import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

const SelectRemote = ({
  name,
  customClass,
  label,
  placeholder,
  extraProps,
  value,
  onChange,
  items,
  renderItem,
  renderInput,
  filterItems,
  onSelectCallback,
  onQueryChange,
  error,
}) => {
  const [cursor, setCursor] = useState(-1);
  const [dropdown, setDropdown] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const listRef = useRef();

  const clickRef = useClickOutside(dropdown, () => {
    setDropdown(false);
    setCursor(-1);
  });

  useEffect(() => {
    if (listRef.current && listRef.current.children[cursor]) {
      listRef.current.children[cursor].focus();
    }
  }, [cursor]);

  const onInputFocus = () => {
    setDropdown(true);
  };

  const onItemSelect = (item) => {
    setDropdown(false);
    onChange(item); 
    setInputValue(renderInput(item));
    onSelectCallback(item);
  };

  const handleKeys = (event) => {
    if (suggestions.length > 0) {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          setCursor((prev) => (prev === -1 ? 0 : Math.min(prev + 1, suggestions.length - 1)));
          break;
        case 'ArrowUp':
          event.preventDefault();
          setCursor((prev) => Math.max(prev - 1, 0));
          break;
        case 'Enter':
          if (cursor !== -1) {
            onItemSelect(suggestions[cursor]);
          }
          break;
        case 'Escape':
          setDropdown(false);
          setCursor(-1);
          break;
        default:
          break;
      }
    }
  };

  const handleOnChange = (change) => {
    setInputValue(change.target.value);
    onQueryChange(change.target.value);
  };

  const queryValue = useMemo(() => {
    return value ? renderInput(value) : inputValue;
  }, [value, renderInput, inputValue]);

  const suggestions = useMemo(() => {
    return typeof inputValue === 'string' ? filterItems(items, inputValue) : items;
  }, [items, inputValue, filterItems]);

  return (
    <div ref={clickRef} className={`form-group ${customClass}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        type="text"
        className={`form-control ${error ? 'is-invalid' : ''}`} 
        name={name}
        placeholder={placeholder}
        value={queryValue}
        onChange={handleOnChange}
        onFocus={onInputFocus}
        onKeyDown={handleKeys}
        autoComplete="off"
        {...extraProps}
        style={{ borderRadius: '5px', padding: '10px' }}
      />
      {dropdown && (
        <div className="dropdown-menu show w-100" style={{ maxHeight: '200px', overflowY: 'auto' }}>
          {suggestions.length > 0 ? (
            <ul className="list-group list-group-flush">
              {suggestions.map((item, i) => (
                <li 
                  key={i} 
                  className="list-group-item-action" 
                  onClick={() => onItemSelect(item)}
                  style={{ cursor: 'pointer' }}
                >
                  {renderItem(item)}
                </li>
              ))}
            </ul>
          ) : (
            <div className="dropdown-item disabled">No items found</div>
          )}
        </div>
      )}
      {error && <div className="invalid-feedback">{error.message}</div>} 
    </div>
  );
};

function useClickOutside(active, onOutsideClick) {
  const node = useRef();
  useEffect(() => {
    function handleClick(event) {
      if (node.current && !node.current.contains(event.target)) {
        onOutsideClick();
      }
    }

    if (active) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [active, onOutsideClick]);

  return node;
}

SelectRemote.propTypes = {
  name: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  extraProps: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  renderInput: PropTypes.func.isRequired,
  filterItems: PropTypes.func.isRequired,
  onSelectCallback: PropTypes.func,
  onQueryChange: PropTypes.func,
  error: PropTypes.object,
};

SelectRemote.defaultProps = {
  customClass: '',
  label: '',
  placeholder: '',
  extraProps: {},
  value: '',
  onSelectCallback: () => {},
  onQueryChange: () => {},
  error: null,
};

export default SelectRemote;
