import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import styles from './formLogin.module.css';
import Input from '../../custom/Input';
import { roAPI } from '#utils/axiosAPI';
import { storeToken } from '#utils/authService';
import { useForm } from 'react-hook-form';

const FormLogin = ({ onAfterSubmit }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (values) => {
    const [res, error] = await of(
      roAPI.post('/auth/login', {
        email: values.email,
        password: values.password,
      }),
    );
    if (error && error.response && error.response.data.type === 'Auth/UserNotActivated') {
      onAfterSubmit('/auth/activate', 'redirect');
    } else if (!error) {
      storeToken(res.token);
      onAfterSubmit(res.token);
    }
  }

  return (
    <div data-testid="FormLogin">
      <form id="formLogin" onSubmit={handleSubmit(onSubmit)} className={`row g-3 ${styles.form}`}>
        <div className="col-12">
          <Input
            label="Correo electrónico"
            placeholder="Correo electrónico"
            type="email"
            customClass="mb-4"
            {...register('email', {
              required: 'Este campo es requerido',
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'Correo electrónico inválido',
              },
            })}
            error={errors.email}
          />
        </div>

        <div className="col-12">
          <Input
            label="Contraseña"
            placeholder="Contraseña"
            type="password"
            customClass="mb-4"
            {...register('password', { required: 'Este campo es requerido' })}
            error={errors.password}
          />
        </div>
      </form>

      <button type="submit" form="formLogin" className={`btn btn-une ${styles['btn-submit']}`}>
        Iniciar sesión
      </button>
    </div>
  );
};

FormLogin.propTypes = {
  onAfterSubmit: PropTypes.func,
};

FormLogin.defaultProps = {
  onAfterSubmit: f => f,
};

export default FormLogin;
