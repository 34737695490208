import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import SelectCoordinateMap from '#components/maps/selectCoordinateMap/selectCoordinateMap';
import useUpdateCities from '#hooks/useUpdateCities';
import ImagePicker from '#components/forms/customForms/imagePicker/imagePicker';
import Input from '../../custom/Input';
import Select from '../../custom/Select';
import TextArea from '../../custom/TextArea';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { roAPI } from '#utils/axiosAPI';
import { useForm, Controller } from 'react-hook-form';

const FormPoi = ({ history, match: { params }, location, cities, city, updateCities, changeCity, loading }) => {
  const { register, handleSubmit, control, setValue, formState: { errors } } = useForm();

  const [poi, setPoi] = useState(() => {
    const initialPOI = {
      address: '',
      latlng: '',
    };
    if (location.state && location.state.poi) {
      return {
        ...initialPOI,
        ...location.state.poi,
        latlng: `${location.state.poi.latitude},${location.state.poi.longitude}`,
      };
    }
    return initialPOI;
  });

  useEffect(() => {
    if (location.state && location.state.poi) {
      changeCity(location.state.poi.id_city);
      setValue('id_city', location.state.poi.id_city);
      setValue('name', location.state.poi.name);
      setValue('description', location.state.poi.description);
      setValue('image', location.state.poi.image || '');
    }
  }, [location.state, changeCity, setValue]);

  useUpdateCities(updateCities, loading, false);

  const onSubmit = async values => {
    try {
      loading.set();
      const newPOI = {
        id_city: values.id_city,
        name: values.name,
        description: values.description,
        address: poi.address,
        latitude: poi.latlng.split(',')[0],
        longitude: poi.latlng.split(',')[1],
      };
      const res = params.id
        ? await roAPI.put(`/pois/${params.id}`, newPOI)
        : await roAPI.post('/pois', newPOI);
      setPoi(prevPOI => ({ ...prevPOI, ...res }));
      if (values.image && typeof values.image !== 'string') {
        const formModel = new FormData();
        formModel.append('image', values.image);
        await roAPI.post(`/pois/${res.id_poi}/image`, formModel);
      }
      history.push('/panel/pois');
      loading.stop();
    } catch (error) {
      loading.stop();
    }
  };

  const handleMarkerAccept = useCallback(result => {
    if (result.addresses.length) {
      setPoi(prevPOI => ({
        ...prevPOI,
        address: result.addresses[0].address.freeformAddress,
        latlng: `${result.position[0].toFixed(6)},${result.position[1].toFixed(6)}`,
      }));
      setValue('address', result.addresses[0].address.freeformAddress);
      setValue('latlng', `${result.position[0].toFixed(6)},${result.position[1].toFixed(6)}`);
    } else {
      setPoi(prevPOI => ({
        ...prevPOI,
        latlng: `${result.position[0].toFixed(6)},${result.position[1].toFixed(6)}`,
      }));
      setValue('latlng', `${result.position[0].toFixed(6)},${result.position[1].toFixed(6)}`);
    }
  }, [setValue]);

  return (
    <div className="container">
      <div className="row">
        <button type="button" className="btn btn-link back" onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
          <span>Regresar</span>
        </button>
      </div>
      
      <div className="row">
        <form id="formPoi" className="row" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-6">
            {/* Ciudad, Nombre, Descripción */}
            <div className="row">
              <Controller
                name="id_city"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="Selecciona una ciudad"
                    label="Ciudad"
                    customClass="col-12"
                    options={cities.map(x => ({ value: x.id_city, label: x.name }))}
                    error={errors.id_city}
                  />
                )}
                rules={{ required: 'Selecciona una ciudad' }}
              />

              <Input
                label="Nombre"
                placeholder="Nombre"
                customClass="col-12"
                {...register('name', { required: 'Este campo es requerido', maxLength: 254 })}
                error={errors.name}
              />

              <TextArea
                label="Descripción"
                placeholder="Descripción del lugar, señas particulares, etc."
                customClass="col-12"
                {...register('description', { required: 'Este campo es requerido', maxLength: 254 })}
                error={errors.description}
                rows={4}
              />
            </div>

            {/* Imagen de POI */}
            <div className="row">
              <Controller
                name="image"
                control={control}
                defaultValue=""
                render={({ field: { value, onChange } }) => (
                  <ImagePicker
                    name="image"
                    label="Imagen de POI"
                    customClass="col-12 col-md-6"
                    value={value}
                    setValue={(_, file) => onChange(file)}
                    serverBasePath={process.env.REACT_APP_IMG_POIS}
                  />
                )}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col-12">
                <label className="label">Ubicación</label>
                <SelectCoordinateMap
                  key={city}
                  showButtons={false}
                  onAccept={handleMarkerAccept}
                  cities={cities}
                  city={city}
                  initialPos={poi.latlng ? [poi.latlng.split(',')[0], poi.latlng.split(',')[1]] : null}
                  poi
                  showShadow={false}
                />
              </div>

              <Input
                label="Dirección"
                placeholder="Calle, colonia, C.P."
                customClass="col-12"
                value={poi.address}
                disabled
              />

              <Input
                label="Latitud y Longitud"
                placeholder="0, 0"
                customClass="col-12"
                value={poi.latlng}
                disabled
              />
            </div>
          </div>

          <div className="col-12">
            <button type="submit" className="btn btn-une btn-submit btn-submit-center">
              Guardar cambios
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

FormPoi.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.any,
  }),
  location: PropTypes.shape({
    state: PropTypes.any,
  }),
  cities: PropTypes.array,
  updateCities: PropTypes.func,
  city: PropTypes.string,
  changeCity: PropTypes.func,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

FormPoi.defaultProps = {
  history: {
    goBack: f => f,
    push: f => f,
  },
  match: {
    params: {},
  },
  location: {
    state: {},
  },
  cities: [],
  updateCities: f => f,
  city: '1',
  changeCity: f => f,
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  state => ({
    cities: state.cities,
    city: state.city,
  }),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    changeCity: city => dispatch(actions.changeCity(city)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(FormPoi);
