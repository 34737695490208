import React from 'react';
import PropTypes from 'prop-types';
import TabsCard from '#components/UI/tabsCard/tabsCard';
import TableBussesConfiguration from '#components/tables/busses/tableBussesConfiguration';
import TableBussesAsign from '#components/tables/busses/tableBussesAsign';
import tableBussesHide from '#components/tables/busses/tableBussesHide';
import TableBussesStatus from '#components/tables/busses/tableBussesStatus';
import TableBusses from '#components/tables/busses/tableBusses';
import FormBus from '#components/forms/formBus/formBus';
import { Route, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRandom, faCog, faEyeSlash, faFileCsv, faBus } from '@fortawesome/free-solid-svg-icons';

const Busses = () => (
  <TabsCard
    title="Control de autobuses"
    tabs={[
      {
        exact: true,
        to: '/panel/autobuses/configuracion',
        render: (
          <>
            <FontAwesomeIcon icon={faCog} style={{ marginRight: '.5rem' }} />
            Configuración de autobuses
          </>
        ),
      },
      {
        exact: true,
        to: '/panel/autobuses/asignacion',
        render: (
          <>
            <FontAwesomeIcon icon={faRandom} style={{ marginRight: '.5rem' }} />
            Asignación de autobuses
          </>
        ),
      },
      {
        exact: true,
        to: '/panel/autobuses/ocultar',
        render: (
          <>
            <FontAwesomeIcon icon={faEyeSlash} style={{ marginRight: '.5rem' }} />
            Ocultar autobuses
          </>
        ),
      },
      {
        exact: true,
        to: '/panel/autobuses/estado',
        render: (
          <>
            <FontAwesomeIcon icon={faFileCsv} style={{ marginRight: '.5rem' }} />
            Estado de autobuses
          </>
        ),
      },
      {
        exact: true,
        to: '/panel/autobuses/gestionar',
        render: (
          <>
            <FontAwesomeIcon icon={faBus} style={{ marginRight: '.5rem' }} />
            Gestionar autobuses
          </>
        ),
      },
    ]}
    routes={[
      <Route exact key="/panel/autobuses/configuracion" path="/panel/autobuses/configuracion" component={TableBussesConfiguration} />,
      <Route exact key="/panel/autobuses/asignacion" path="/panel/autobuses/asignacion" component={TableBussesAsign} />,
      <Route exact key="/panel/autobuses/ocultar" path="/panel/autobuses/ocultar" component={tableBussesHide} />,
      <Route exact key="/panel/autobuses/estado" path="/panel/autobuses/estado" component={TableBussesStatus} />,
      <Route exact key="/panel/autobuses/gestionar" path="/panel/autobuses/gestionar" component={TableBusses} />,
      <Route exact key="/panel/autobuses/gestionar/autobus" path="/panel/autobuses/gestionar/autobus" component={FormBus} />,
      <Route exact key="/panel/autobuses/gestionar/autobus/:id" path="/panel/autobuses/gestionar/autobus/:id" component={FormBus} />,
      <Redirect key="/panel/autobuses" from="/panel/autobuses" to="/panel/autobuses/configuracion" />,
    ]}
  />
);

Busses.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Busses.defaultProps = {
  match: {},
};

export default Busses;
