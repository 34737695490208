import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatetimePicker from '#components/forms/customForms/datetimePicker/datetimePicker';
import Input from '../../custom/Input';
import Select from '../../custom/Select';
import TextArea from '../../custom/TextArea';
import MultiSelect from '#components/forms/customForms/multiSelect/multiSelect';
import { useForm, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { roAPI } from '#utils/axiosAPI';

const FormNotification = ({ history, cities }) => {
  const { register, handleSubmit, control, formState: { errors } } = useForm();

  const onSubmit = async values => {
    try {
      const send_date = values.send_date ? moment(values.send_date, 'YYYY-MM-DD:HH:mm').toISOString() : null;
      const level = values.level === 'linea' ? 'importante' : values.level;
      const topic = values.city.map(item => `avisos_${item.id_city}_${level}`).join(',');

      await roAPI.post('/notifications', {
        title: values.title,
        message: values.message,
        topic,
        ...(send_date && { send_date }),
      });

      history.push('/panel/notificaciones');
    } catch (error) {
      // Manejo de errores
    }
  };

  const topics = React.useMemo(() => {
    const allowed = process.env.REACT_APP_PUSH === 'true';
    return allowed
      ? [
          { label: 'Aviso general', value: 'general' },
          { label: 'Aviso importante', value: 'importante' },
          { label: 'Aviso de línea', value: 'linea' },
          { label: 'Encuesta general', value: 'encuesta' },
          { label: 'Encuesta registrados', value: 'encuesta_registrados' },
          { label: 'Pruebas', value: 'prueba' },
        ]
      : [{ label: 'Pruebas', value: 'prueba' }];
  }, []);

  return (
    <div data-testid="FormNotification">
      <div className="row">
        <div className="col-12">
          <button type="button" className="btn btn-link back" onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
            <span>Regresar</span>
          </button>
        </div>
      </div>
      <p>Registra una nueva notificación que será enviada a todos los dispositivos que cuenten con la aplicación.</p>

      <form id="formNotification" onSubmit={handleSubmit(onSubmit)} className="row g-3">
        <Input
          customClass="col-12 col-md-4"
          label="Título"
          placeholder="Inserte título"
          type="text"
          {...register('title', { required: 'Este campo es requerido', maxLength: 255 })}
          error={errors.title}
        />

        <Controller
          control={control}
          name="city"
          rules={{ required: 'Este campo es requerido' }}
          render={({ field: { onChange, value } }) => (
            <MultiSelect
              name="city"
              customClass="col-md-4"
              label="Ciudad"
              placeholder="Seleccione ciudad"
              items={cities}
              itemKey="id_city"
              value={value}
              onChange={onChange}
              renderItem={item => item.name}
              renderInput={item => item.name}
              error={errors.city}
            />
          )}
        />

        <Select
          defaultValue=""
          customClass="col-12 col-md-4"
          label="Tipo de notificación"
          placeholder="Seleccione el tipo"
          options={topics}
          {...register('level', { required: 'Este campo es requerido' })}
          error={errors.level}
        />

        <TextArea
          label="Mensaje"
          placeholder="Inserte su mensaje"
          {...register('message', { required: 'Este campo es requerido', maxLength: 1024 })}
          error={errors.message}
          customClass="col-12"
          style={{ height: '30px' }}
        />

        <div className="col-12">
          <p className="form-separator">Programación (Opcional)</p>
        </div>

        <div className="col-4">
          <Controller
            control={control}
            name="send_date"
            render={({ field: { onChange, value } }) => (
              <DatetimePicker 
                name="send_date"
                label="Fecha y hora de envío" 
                onChange={onChange} 
                value={value} 
                errors={errors.send_date} 
              />
            )}
          />
        </div>

        <div className="col-12">
          <button type="submit" className="btn btn-une btn-submit btn-submit-center">
            Publicar notificación
          </button>
        </div>
      </form>
    </div>
  );
};

FormNotification.propTypes = {
  history: PropTypes.any,
  cities: PropTypes.array,
};

FormNotification.defaultProps = {
  history: {},
  cities: [],
};

export default FormNotification;
