import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import SelectCoordinateMap from '#components/maps/selectCoordinateMap/selectCoordinateMap';
import useUpdateCities from '#hooks/useUpdateCities';
import ImagePicker from '#components/forms/customForms/imagePicker/imagePicker';
import TimePicker from '#components/forms/customForms/timePicker/timePicker';
import Input from '../../custom/Input';
import Select from '../../custom/Select';
import TextArea from '../../custom/TextArea';
import styles from './credentials.module.css';
import { useForm, Controller } from 'react-hook-form';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { roAPI } from '#utils/axiosAPI';

const FormRechargePoint = ({
  history,
  match: { params },
  location,
  cities,
  city,
  updateCities,
  changeCity,
  loading,
}) => {
  const { register, control, setValue, handleSubmit, formState: { errors } } = useForm();

  const [rp, setRp] = useState(() => {
    const initialRP = {
      address: '',
      latlng: '',
    };
    if (location.state && location.state.rp) {
      return {
        ...initialRP,
        ...location.state.rp,
        ...location.state.rp.attention_hours,
        latlng: `${location.state.rp.latitude},${location.state.rp.longitude}`,
      };
    }
    return initialRP;
  });

  useEffect(() => {
    if (location.state && location.state.rp) {
      changeCity(location.state.rp.id_city);
      setValue('id_city', location.state.rp.id_city);
      setValue('type', location.state.rp.type);
      setValue('name', location.state.rp.name);
      setValue('telephone', location.state.rp.telephone || '');
      setValue('description', location.state.rp.description);
      setValue('service_start', location.state.rp.attention_hours.service_start);
      setValue('service_end', location.state.rp.attention_hours.service_end);
      setValue('service_start_saturday', location.state.rp.attention_hours.service_start_saturday);
      setValue('service_end_saturday', location.state.rp.attention_hours.service_end_saturday);
      setValue('service_start_sunday', location.state.rp.attention_hours.service_start_sunday);
      setValue('service_end_sunday', location.state.rp.attention_hours.service_end_sunday);
      setValue('image', location.state.rp.image || '');
    }
  }, [location.state, changeCity, setValue]);

  useUpdateCities(updateCities, loading, false);

  const onSubmit = async values => {
    try {
      loading.set();
      const newRP = {
        id_city: values.id_city,
        type: values.type,
        name: values.name,
        description: values.description,
        address: rp.address,
        telephone: values.telephone || '',
        latitude: rp.latlng.split(',')[0],
        longitude: rp.latlng.split(',')[1],
        attention_hours: {
          service_start: values.service_start,
          service_end: values.service_end,
          service_start_saturday: values.service_start_saturday,
          service_end_saturday: values.service_end_saturday,
          service_start_sunday: values.service_start_sunday,
          service_end_sunday: values.service_end_sunday,
        },
      };
      const res = params.id ? await roAPI.put(`/rps/${params.id}`, newRP) : await roAPI.post('/rps', newRP);
      setRp(prevRP => ({ ...prevRP, ...res }));
      if (values.image && typeof values.image !== 'string') {
        const formModel = new FormData();
        formModel.append('image', values.image);
        await roAPI.post(`/rps/${res.id_rp}/image`, formModel);
      }
      history.push('/panel/credencializacion');
      loading.stop();
    } catch (error) {
      loading.stop();
    }
  };

  const handleMarkerAccept = useCallback(result => {
    if (result.addresses.length) {
      setRp(prevRP => ({
        ...prevRP,
        address: result.addresses[0].address.freeformAddress,
        latlng: `${result.position[0].toFixed(6)},${result.position[1].toFixed(6)}`,
      }));
      setValue('address', result.addresses[0].address.freeformAddress);
      setValue('latlng', `${result.position[0].toFixed(6)},${result.position[1].toFixed(6)}`);
    } else {
      setRp(prevRP => ({
        ...prevRP,
        latlng: `${result.position[0].toFixed(6)},${result.position[1].toFixed(6)}`,
      }));
      setValue('latlng', `${result.position[0].toFixed(6)},${result.position[1].toFixed(6)}`);
    }
  }, [setValue]);

  return (
  <div className="row">
    <div className="col-12 d-flex align-items-center mb-3">
        <button type="button" className="btn btn-link back" onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
          <span>Regresar</span>
        </button>
    </div>

    <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
      <div className="col-12 col-md-6">
        <div className="row">
          <div className="col-12 col-md-6">

            <Controller
              name="id_city"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Selecciona una ciudad"
                  label="Ciudad"
                  options={cities.map(x => ({ value: x.id_city, label: x.name }))}
                  customClass="col-12"
                  error={errors.id_city}
                />
              )}
              rules={{ required: 'Selecciona una ciudad' }}
            />
          </div>

          <div className="col-12 col-md-6">
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Tipo de ubicación"
                  options={[
                    { value: 1, label: 'Punto de recarga' },
                    { value: 2, label: 'Centro credencialización' },
                  ]}
                  customClass="col-12"
                  error={errors.type}
                />
              )}
              rules={{ required: 'Selecciona un tipo' }}
            />
          </div>

          <div className="col-12 col-md-6">
            <Input
              label="Nombre"
              placeholder="Nombre"
              customClass="col-12"
              {...register('name', { required: 'Este campo es requerido', maxLength: 254 })}
              error={errors.name}
            />
          </div>

          <div className="col-12 col-md-6">
            <Input
              label="Teléfono"
              placeholder="Teléfono"
              customClass="col-12"
              {...register('telephone', { pattern: { value: /^\d{10}$/, message: 'Número a 10 dígitos' } })}
              error={errors.telephone}
            />
          </div>

          <div className="col-12">
            <TextArea
              label="Descripción"
              placeholder="Descripción del lugar, señas particulares, etc."
              customClass="col-12"
              {...register('description', { required: 'Este campo es requerido', maxLength: 254 })}
              error={errors.description}
            />
          </div>

          <div className="col-12">
            <p className={styles['horas']}>Horarios de atención</p>
          </div>

          <div className="col-12 col-md-6">
            <Controller
              name="service_start"
              control={control}
              render={({ field }) => (
                <TimePicker
                  {...field}
                  label="Inicio lunes - viernes"
                  customClass="col-12"
                  error={errors.service_start}
                />
              )}
              rules={{ required: 'Este campo es requerido' }}
            />
          </div>

          <div className="col-12 col-md-6">
            <Controller
              name="service_end"
              control={control}
              render={({ field }) => (
                <TimePicker
                  {...field}
                  label="Fin lunes - viernes"
                  customClass="col-12"
                  error={errors.service_end}
                />
              )}
              rules={{ required: 'Este campo es requerido' }}
            />
          </div>

          <div className="col-12 col-md-6">
            <Controller
              name="service_start_saturday"
              control={control}
              render={({ field }) => (
                <TimePicker
                  {...field}
                  label="Inicio sábado"
                  customClass="col-12"
                  error={errors.service_start_saturday}
                />
              )}
              rules={{ required: 'Este campo es requerido' }}
            />
          </div>

          <div className="col-12 col-md-6">
            <Controller
              name="service_end_saturday"
              control={control}
              render={({ field }) => (
                <TimePicker
                  {...field}
                  label="Fin sábado"
                  customClass="col-12"
                  error={errors.service_end_saturday}
                />
              )}
              rules={{ required: 'Este campo es requerido' }}
            />
          </div>

          <div className="col-12 col-md-6">
            <Controller
              name="service_start_sunday"
              control={control}
              render={({ field }) => (
                <TimePicker
                  {...field}
                  label="Inicio domingo"
                  customClass="col-12"
                  error={errors.service_start_sunday}
                />
              )}
              rules={{ required: 'Este campo es requerido' }}
            />
          </div>

          <div className="col-12 col-md-6">
            <Controller
              name="service_end_sunday"
              control={control}
              render={({ field }) => (
                <TimePicker
                  {...field}
                  label="Fin domingo"
                  customClass="col-12"
                  error={errors.service_end_sunday}
                />
              )}
              rules={{ required: 'Este campo es requerido' }}
            />
          </div>
        </div>
      </div>

      <div className="col-12 col-md-6">
        <div className="row">
          <div className="col-12">
            <label className="label">Ubicación</label>
            <SelectCoordinateMap
              key={city}
              showButtons={false}
              onAccept={handleMarkerAccept}
              cities={cities}
              city={city}
              initialPos={rp.latlng ? [rp.latlng.split(',')[0], rp.latlng.split(',')[1]] : null}
              poi
              showShadow={false}
            />
          </div>
          <div className="col-12">
            <Input
              label="Dirección"
              placeholder="Calle, colonia, C.P."
              customClass="col-12"
              value={rp.address}
              disabled
            />
          </div>

          <div className="col-12 mt-2">
            <Input
              label="Latitud y Longitud"
              placeholder="0, 0"
              customClass="col-12"
              value={rp.latlng}
              disabled
            />
          </div>

          <div className="col-12 mt-2">
            <Controller
              name="image"
              control={control}
              render={({ field: { value, onChange } }) => (
                <ImagePicker
                  name="image"
                  label="Imagen de RP"
                  customClass="col-12"
                  value={value}
                  setValue={(name, file) => onChange(file)}
                  serverBasePath={process.env.REACT_APP_IMG_RP}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className="col-12 text-center">
        <button type="submit" className="btn btn-une btn-submit">
          Guardar cambios
        </button>
      </div>
    </form>
  </div>
  );
};

FormRechargePoint.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.any,
  }),
  location: PropTypes.shape({
    state: PropTypes.any,
  }),
  cities: PropTypes.array,
  updateCities: PropTypes.func,
  city: PropTypes.string,
  changeCity: PropTypes.func,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

FormRechargePoint.defaultProps = {
  history: {
    goBack: f => f,
    push: f => f,
  },
  match: {
    params: {},
  },
  location: {
    state: {},
  },
  cities: [],
  updateCities: f => f,
  city: '1',
  changeCity: f => f,
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  state => ({
    cities: state.cities,
    city: state.city,
  }),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    changeCity: city => dispatch(actions.changeCity(city)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(FormRechargePoint);
