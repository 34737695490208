import React from 'react';
import PropTypes from 'prop-types';

const Select = React.forwardRef(({ label, placeholder, options, error, customClass = "", ...selectProps }, ref) => {
  return (
    <div className={`${customClass}`}>
      {label && <label className="form-label">{label}</label>}
      <select
        ref={ref}
        className={`form-control ${error ? 'is-invalid' : ''}`}
        {...selectProps}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <div className="invalid-feedback">{error.message}</div>}
    </div>
  );
});

Select.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  error: PropTypes.object,
  customClass: PropTypes.string,
};

Select.defaultProps = {
  customClass: "",
};

export default Select;
