import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BooleanButtons from '#components/forms/customForms/booleanButtons/booleanButtons';
import SelectBoundsMap from '#components/maps/selectBoundsMap/selectBoundsMap';
import ColorPicker from '#components/forms/customForms/colorPicker/colorPicker';
import ImagePicker from '#components/forms/customForms/imagePicker/imagePicker';
import Input from '../../custom/Input';
import TextArea from '../../custom/TextArea';
import { useForm, Controller } from 'react-hook-form';
import { roAPI } from '#utils/axiosAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';

const FormCity = ({ history, match: { params }, location, loading }) => {
  const { register, control, watch, setValue, handleSubmit, formState: { errors } } = useForm();

  const cityData = location.state?.city;

  const defaultCity = {
    ne_string: '',
    sw_string: '',
    color: '#FFFFFF',
    notifications_enabled: false,
    disabled: false,
  };

  const [city, setCity] = React.useState(cityData ? { ...defaultCity, ...cityData } : defaultCity);

  const initialBounds = React.useMemo(() => {
    if (cityData) {
      return {
        initialNE: [parseFloat(cityData.ne_latitude), parseFloat(cityData.ne_longitude)],
        initialSW: [parseFloat(cityData.sw_latitude), parseFloat(cityData.sw_longitude)],
      };
    }
    return {
      initialNE: null,
      initialSW: null,
    };
  }, [cityData]);

  useEffect(() => {
    if (cityData) {
      setValue('name', cityData.name);
      setValue('description', cityData.description);
      setValue('color', cityData.color || '#FFFFFF');
      setValue('notifications_enabled', cityData.notifications_enabled);
      setValue('disabled', cityData.disabled);
      setValue('ne_string', `${cityData.ne_latitude}, ${cityData.ne_longitude}`);
      setValue('sw_string', `${cityData.sw_latitude}, ${cityData.sw_longitude}`);
      setValue('banner_url', cityData.banner_url || '');
      setValue('logo', cityData.logo);
      setValue('banner', cityData.banner); 
    }
  }, [cityData, setValue]);

  const onSubmit = async values => {
    try {
      loading.set();
      const newCity = {
        name: values.name,
        description: values.description,
        color: values.color,
        ne_latitude: city.ne_latitude,
        ne_longitude: city.ne_longitude,
        sw_latitude: city.sw_latitude,
        sw_longitude: city.sw_longitude,
        notifications_enabled: !!values.notifications_enabled,
        disabled: !!values.disabled,
      };
      const res = params.id ? await roAPI.put(`/cities/${params.id}`, newCity) : await roAPI.post('/cities', newCity);
      setCity(prevCity => ({ ...prevCity, ...res }));
      if (values.logo) {
        if (typeof values.logo !== 'string') {
          const formModel = new FormData();
          formModel.append('image', values.logo);
          await roAPI.post(`/cities/${res.id_city}/logo`, formModel);
        }
      }
      if (values.banner || values.banner_url) {
        const formModel = new FormData();
        if (values.banner && typeof values.banner !== 'string') {
          formModel.append('image', values.banner);
        }
        formModel.append('banner_url', values.banner_url);
        await roAPI.post(`/cities/${res.id_city}/banner`, formModel);
      }
      history.push('/panel/ciudades');
      loading.stop();
    } catch (error) {
      loading.stop();
    }
  };

  const handleOnBounds = React.useCallback(bounds => {
    const ne_lat = bounds.neLatLng[0].toFixed(6);
    const ne_lng = bounds.neLatLng[1].toFixed(6);
    const sw_lat = bounds.swLatLng[0].toFixed(6);
    const sw_lng = bounds.swLatLng[1].toFixed(6);
    setCity(prevBounds => ({
      ...prevBounds,
      ne_string: `${ne_lat}, ${ne_lng}`,
      sw_string: `${sw_lat}, ${sw_lng}`,
      ne_latitude: ne_lat,
      ne_longitude: ne_lng,
      sw_latitude: sw_lat,
      sw_longitude: sw_lng,
    }));
    setValue('ne_string', `${ne_lat}, ${ne_lng}`);
    setValue('sw_string', `${sw_lat}, ${sw_lng}`);
  }, [setValue]);

  const deleteBanner = async () => {
    loading.set();
    try {
      await roAPI.delete(`/cities/${params.id}/banner`);
      history.push('/panel/ciudades');
    } catch (error) {
      console.error(error);
    }
    loading.stop();
  };

  return (
    <div className="row">
      <div className="col-12 mb-3">
        <button type="button" className="btn btn-link back" onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
          <span>Regresar</span>
        </button>
      </div>
      <div className="col-12">
        <form key={params.id} id="formCity" onSubmit={handleSubmit(onSubmit)} className="row g-3">
          <div className="col-12 col-md-6">
            <Input 
              customClass="mb-3"
              label="Nombre"
              placeholder="Nombre"
              {...register('name', { required: 'Este campo es requerido', maxLength: 255 })}
              error={errors.name}
            />

            <TextArea
              label="Descripción de ciudad"
              placeholder="Descripción de ciudad"
              {...register('description', { required: 'Este campo es requerido', maxLength: 1024 })}
              error={errors.description}
              style={{ height: '80px' }}
            />

            <div className="row g-2">
              <div className="col-6">
                <Controller
                  name="notifications_enabled"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <BooleanButtons
                      {...field}
                      label="Notificaciones activas"
                      trueText="Activas"
                      falseText="Inactivas"
                      invert={false}
                      error={error}
                    />
                  )}
                />
              </div>
              <div className="col-6">
                <Controller
                  name="disabled"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <BooleanButtons
                      {...field}
                      label="Ciudad activa"
                      trueText="Activa"
                      falseText="Inactiva"
                      invert={true}
                      error={error}
                    />
                  )}
                />
              </div>
            </div>

            <div className="row g-2 mt-3">
              <div className="col-6">
                <Controller
                  name="logo"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <ImagePicker
                      name="logo"
                      label="Icono de ciudad"
                      value={value} 
                      setValue={(name, file) => onChange(file)} 
                      serverBasePath={process.env.REACT_APP_IMG_CIUDAD} 
                      help="El icono debe tener un aspecto 1:1 (Ej. 600px x 600px), formato PNG y de poco peso (50KB)."
                    />
                  )}
                />
              </div>
              <div className="col-6">
                <ColorPicker
                  name="color"
                  label="Color de interfaz"
                  value={watch('color')}
                  onChange={val => setValue('color', val)}
                  error={errors.color}
                />
              </div>
            </div>

            <div className="col-12 mt-4">
              <p className="form-separator">Banner publicitario (Opcional)</p>
            </div>

            <div className="row g-2">
              <div className="col-6">
                <Controller
                  name="banner"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <ImagePicker
                      name="banner"
                      label="Banner publicitario"
                      value={value} 
                      setValue={(name, file) => onChange(file)}  
                      serverBasePath={`${process.env.REACT_APP_IMG_CIUDAD}/banners`}
                      help="El banner debe tener un aspecto 4:1 (Ej. 1500px x 375px), formato PNG o JPG y de poco peso (500KB)."
                    />
                  )}
                />
              </div>
              <div className="col-6">
                <Input
                  label="Enlace de banner"
                  placeholder="Enlace de banner"
                  {...register('banner_url')}
                  error={errors.banner_url}
                />

                {city.banner && (
                  <button type="button" className="btn btn-link btn-link-eliminar mt-2" onClick={deleteBanner}>
                    <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: '.25rem' }} />
                    <span>Eliminar banner</span>
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <label className="label">Zona de ciudad</label>
            <SelectBoundsMap onBounds={handleOnBounds} initialNE={initialBounds.initialNE} initialSW={initialBounds.initialSW} />

            <div className="row g-2 mt-3">
              <div className="col-6">
                <Input
                  label="Noreste"
                  placeholder="0.0, 0.0"
                  {...register('ne_string')}
                  error={errors.ne_string}
                  value={city.ne_string}
                  disabled
                />
              </div>
              <div className="col-6">
                <Input
                  label="Suroeste"
                  placeholder="0.0, 0.0"
                  {...register('sw_string')}
                  error={errors.sw_string}
                  value={city.sw_string}
                  disabled
                />
              </div>
            </div>

            <div className="col-12 mt-2">
              <p>Seleccione la esquina Noreste y Suroeste de la ciudad.</p>
            </div>
          </div>
        </form>

        <button type="submit" form="formCity" className="btn btn-une btn-submit btn-submit-center mt-4">
          Guardar cambios
        </button>
      </div>
    </div>
  );
};

FormCity.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.any,
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      city: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        color: PropTypes.string,
        notifications_enabled: PropTypes.bool,
        disabled: PropTypes.bool,
        ne_latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        ne_longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        sw_latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        sw_longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        banner_url: PropTypes.string,
      }),
    }),
  }),
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

FormCity.defaultProps = {
  history: {
    goBack: f => f,
    push: f => f,
  },
  match: {
    params: {},
  },
  location: {
    state: {
      city: null,
    },
  },
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  null,
  dispatch => ({
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(FormCity);
