import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import moment from 'moment';
import 'moment/locale/es';
import confirmModal from '#components/modals/confirm/confirm';
import styles from './busses.module.css';
import useUpdateCities from '#hooks/useUpdateCities';
import Select from '../../custom/Select';
import SelectRemote from '../../custom/SelectRemote';
import useDynamicTable from '#hooks/useDynamicTable';
import DataTable from '../DataTable';
import { roAPI } from '#utils/axiosAPI';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRandom, faTimes, faCaretUp, faCaretDown, faEraser } from '@fortawesome/free-solid-svg-icons';
import { useForm, Controller } from 'react-hook-form';

const TableBussesAsign = ({ loading, cities, updateCities }) => {
  const { handleSubmit, control, formState: { errors } } = useForm();
  const [key, setKey] = React.useState(Math.random());
  const [busses, setBusses] = React.useState([]);
  const [city, setCity] = React.useState('');
  const [lines, setLines] = React.useState([]);
  const [rawAssigns, setRawassigns] = React.useState([]);
  const [totalAssigns, setTotalassigns] = React.useState(0);
  const [assignsByLine, setAssignsbyline] = React.useState([]);
  const [dropdowns, setDropdowns] = React.useState({
    busses: false,
    lines: true,
  });

  // TODO:
  // Revisar si al borrar la forma se borra también state:city
  // Y buscar una forma inteligente de actualizar la tabla, pero borrando la forma.

  const getAssigns = React.useCallback(
    async ({ size, page, search }) => {
      loading.set();
      try {
        if (!city) {
          return { rows: [], count: 0 };
        }
  
        const params = {
          limit: size,
          page,
          ...(search && { search }),
          ...(lines.length && { id_route: lines.map(x => x.id_route).join(',') }),
        };
  
        const resp = await roAPI.get('/vehicles/assigns', { params });
  
        if (resp && resp.rows && resp.count !== undefined) {
          setRawassigns(resp.rows);
          setTotalassigns(parseInt(resp.count, 10));
  
          return {
            rows: resp.rows,
            count: resp.count,
          };
        } else {
          return { rows: [], count: 0 };
        }
      } catch (error) {
        return { rows: [], count: 0 };
      } finally {
        loading.stop();
      }
    },
    [loading, lines, city],
  );

  const {
    count,
    currentPage,
    size,
    handlePageChange,
    handleSizeChange,
    handleSearch,
    loadItems
  } = useDynamicTable(getAssigns, 1, 10);

  const getAssignsByLine = React.useCallback(async () => {
    loading.set();
    const [res, err] = await of(
      roAPI.get(`/vehicles/assigns/routes`, {
        params: {
          id_city: city,
        },
      }),
    );
    if (!err) {
      setAssignsbyline(res);
    }
    loading.stop();
  }, [loading, city]);

  useUpdateCities(updateCities, loading, false);

  React.useEffect(() => {
    async function getBusses() {
      try {
        loading.set();
        const busses = await roAPI.get('/vehicles/all');
        if (Array.isArray(busses)) {
          setBusses(busses);
        }
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    getBusses();
  }, [loading]);

  React.useEffect(() => {
    async function getLines() {
      loading.set();
      const [res, err] = await of(roAPI.get(`/routes/city/${city}`));
      if (!err) {
        const flat = res.reduce((accumulator, line) => {
          accumulator.push(line);
          line.route_unions.forEach(child => {
            accumulator.push(child);
          });
          return accumulator;
        }, []);
        setLines(flat);
      }
      loading.stop();
    }
    if (city) {
      getLines();
    }
  }, [city, loading]);

  React.useEffect(() => {
    if (city) {
      getAssignsByLine();
    }
  }, [city, getAssignsByLine]);

  const onSubmit = async values => {
    async function doSubmit() {
      loading.set();
      const [, err] = await of(
        roAPI.post('/vehicles/assigns', {
          id_route: values.line,
          vehicle_code: values.bus.code,
        }),
      );
      if (!err) {
        setKey(Math.random());
        getAssignsByLine();
        loadItems();
      }
      loading.stop();
    }
    confirmModal({
      message: (
        <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
          El autobús se mostrará en la línea asignada y dejara de mostrarse en su línea original.
          <br />
          ¿Desea continuar?
        </p>
      ),
      buttons: [
        {
          label: 'Cancelar',
          class: 'btn-secondary',
        },
        {
          label: 'Aceptar',
          class: 'btn-une',
          onClick: doSubmit,
        },
      ],
    });
  };

  const removeAssign = React.useCallback(
    code => {
      async function doRemove() {
        const [, err] = await of(roAPI.delete(`/vehicles/assigns/${code}`));
        if (!err) {
          // Reload cochino.
          const elem = document.getElementById('wafotable-reload');
          elem.click();
          getAssignsByLine();
        }
      }
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            El autobús ya no se mostrará en la línea asignada.
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: doRemove,
          },
        ],
      });
    },
    [getAssignsByLine],
  );

  const removeAllAssign = React.useCallback(() => {
    async function doRemove() {
      try {
        loading.set();
        // Deleting
        await roAPI.delete('/vehicles/assigns/purge');
        getAssignsByLine();
        loadItems();
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    confirmModal({
      message: (
        <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
          Todos los autobuses ya no se mostraran en las líneas asignadas.
          <br />
          ¿Desea continuar?
        </p>
      ),
      buttons: [
        {
          label: 'Cancelar',
          class: 'btn-secondary',
        },
        {
          label: 'Aceptar',
          class: 'btn-une',
          onClick: doRemove,
        },
      ],
    });
  }, [loading, getAssignsByLine, loadItems]);

  const assigned = React.useMemo(() => {
    if (busses.length && rawAssigns.length && lines.length && city) {
      const linesObj = {};
      lines.forEach(x => {
        linesObj[x.id_route] = x;
      });
  
      return rawAssigns.map(assign => {
        return {
          code: assign.vehicle_code,
          old_line: linesObj[assign.old_id_route],
          line: linesObj[assign.id_route],
          date: assign.created_at,
          options: assign.vehicle_code,
        };
      });
    }
    return [];
  }, [busses, city, lines, rawAssigns]);

  const columnsConfig = React.useMemo(
    () => ({
      code: val => <span>{val}</span>,
      old_line: val => <span>{val ? val.name : 'Línea no encontrada'}</span>,
      line: val => <span>{val ? val.name : 'Línea no encontrada'}</span>, 
      date: val => <span>{moment(val).format('DD-MM-YYYY HH:mm')}</span>, 
      options: {
        render: function col(val) {
          return (
            <>
              <button type="button" className="btn btn-sm btn-danger" onClick={() => removeAssign(val)}>
                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '.5rem' }} />
                Cancelar
              </button>
            </>
          );
        },
        style: {
          whiteSpace: 'nowrap',
        },
      },
    }),
    [removeAssign],
  );

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <h5>Asignación de autobuses</h5>
        </div>
      </div>

      <form key={key} locale="es" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-4">
            <Controller
              name="id_city"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Selecciona una ciudad"
                  label="Ciudad"
                  options={cities.map(x => ({ value: x.id_city, label: x.name }))}
                  customClass=""
                  error={errors.id_city}
                  onChange={(e) => {
                    field.onChange(e.target.value); 
                    setCity(e.target.value); 
                  }}
                />
              )}
              rules={{ required: 'Selecciona una ciudad' }}
            />
          </div>

          <div className="col-md-4">
            <Controller
              name="line"
              control={control}
              defaultValue=""
              rules={{ required: 'Selecciona una línea' }} 
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Seleccione línea"
                  label="Línea"
                  options={lines.filter(x => x.id_route && x.id_route !== -1).map(x => ({
                    value: x.id_route,
                    label: x.name, 
                  }))}
                  customClass=""
                  error={errors.line}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                />
              )}
            />
          </div>

          <div className="col-md-4">
            <Controller
              name="bus"
              control={control}
              render={({ field }) => (
                <SelectRemote
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  customClass=""
                  label="Autobús"
                  placeholder="Seleccione un autobús"
                  items={busses}
                  filterItems={(items, query) =>
                    items.filter(item => item.code.toLowerCase().includes(query.toLowerCase()))
                  }
                  renderInput={item => `${item.code}`}
                  renderItem={item => <p className="autocomplete-item">Unidad: {item.code}</p>}
                  error={errors.bus}
                />
              )}
              rules={{ required: 'Selecciona un autobús' }}
            />
          </div>
        </div>

        <div className="row mt-3 mb-2">
          <div className="col-12">
            <button type="submit" className="btn btn-une">
              <FontAwesomeIcon icon={faRandom} style={{ marginRight: '.5rem' }} />
              <span>Asignar</span>
            </button>
          </div>
        </div>
      </form>

      <div className="row">
        <div className="col-12">
          <p className={styles['desc']}>
            La asignación manual de autobuses a línea permite configurar temporalmente la unidad en cualquier línea de su elección.
            Seleccione la unidad y línea deseadas para realizar la asignación.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className={styles['sub-separador']} onClick={() => setDropdowns(prev => ({ ...prev, lines: !prev.lines }))}>
            <h5>Número de autobuses asignados por línea</h5>
            <FontAwesomeIcon icon={dropdowns.lines ? faCaretUp : faCaretDown} />
          </div>
        </div>
      </div>

      <div className={`row justify-content-center ${dropdowns.lines ? styles['toggled'] : ''}`}>
        <div className="col-12" style={{ marginBottom: '2rem' }}>
          <div className="row">
            {assignsByLine.map(x => (
              <div key={x.id_route} className="col-6">
                <div className={styles['line']}>
                  <span>
                    <strong>{x.name}</strong>
                  </span>
                  <span>{x.total} Unidades</span>
                </div>
              </div>
            ))}
            {!city && assignsByLine.length < 1 && (
              <div className="col-12">
                <p>Seleccione una ciudad para continuar</p>
              </div>
            )}
            {city && assignsByLine.length < 1 && (
              <div className="col-12">
                <p>Sin asignaciones en la ciudad</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className={styles['sub-separador']} onClick={() => setDropdowns(prev => ({ ...prev, busses: !prev.busses }))}>
            <h5>Autobuses asignados a otras líneas</h5>
            <FontAwesomeIcon icon={dropdowns.busses ? faCaretUp : faCaretDown} />
          </div>
        </div>
      </div>

      <div className={`row ${dropdowns.busses ? styles['toggled'] : ''}`}>
        <div className="col-12">
          <DataTable
            columns={['Código unidad', 'Línea original', 'Línea asignada', 'Fecha de asignación', 'Opciones']}
            list={assigned}
            currentPage={currentPage}
            size={size}
            totalRows={count}
            handlePageChange={handlePageChange}
            handleSizeChange={handleSizeChange}
            handleSearch={handleSearch}
            columnsConfig={columnsConfig}
            tableClass="table table-striped table-sm"
            tableWrapperClass="table-une"
            noRowsMessage={city ? 'Sin asignaciones en la ciudad' : 'Seleccione una ciudad para continuar'}
            loadItems={loadItems}
            customFilters={
              <button
                type="button"
                className="btn btn-primary"
                disabled={totalAssigns === 0}
                onClick={removeAllAssign}
              >
                <FontAwesomeIcon icon={faEraser} />
              </button>
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
};

TableBussesAsign.propTypes = {
  cities: PropTypes.array,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
  updateCities: PropTypes.func,
};

TableBussesAsign.defaultProps = {
  cities: [],
  loading: {
    set: f => f,
    stop: f => f,
  },
  updateCities: f => f,
};

export default reduxConnect(
  state => ({
    cities: state.cities,
  }),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(TableBussesAsign);
