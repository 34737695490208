import React from 'react';
import PropTypes from 'prop-types';
import ImagePicker from '#components/forms/customForms/imagePicker/imagePicker';
import { useForm, Controller } from 'react-hook-form';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';

const FormCalendar = ({ loading, alert }) => {
  const { control, handleSubmit, setValue } = useForm();
  const [current, setCurrent] = React.useState('');

  React.useEffect(() => {
    async function getCurrent() {
      try {
        loading.set();
        const resp = await roAPI.get('/monitor/cache');
        setCurrent(resp.credentials_validity_file);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    getCurrent();
  }, [loading]);

  const onSubmit = async (data) => {
    const { image } = data;
    if (image) {
      try {
        loading.set();
        const formModel = new FormData();
        formModel.append('file', image);
        const resp = await roAPI.post('/credentials/calendar/validity', formModel);
        setCurrent(resp.file);
        alert({
          type: 'success',
          title: 'Imagen actualizada',
          message:
            'Se ha reemplazado la imagen exitosamente. Los cambios se verán reflejados en la aplicación y sitio web.',
          timer: 3000,
        });
        setValue('image', '');  // Reset the image after successful upload
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
  };

  return (
    <div>
      <h5 style={{ color: '#4f4f4f' }}>Imagen actual</h5>
      <div
        style={{
          backgroundColor: '#ccc',
          borderRadius: '.25rem',
          maxHeight: '300px',
          overflowY: 'auto',
          marginBottom: '1rem',
          padding: '1rem 2.5rem',
        }}
      >
        <img
          src={`${process.env.REACT_APP_IMG_CALENDAR}/${current}`}
          alt="Calendario"
          style={{
            maxWidth: '100%',
            margin: 'auto',
            display: 'block',
          }}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <Controller
            name="image"
            control={control}
            render={({ field: { value, onChange } }) => (
              <ImagePicker
                name="image"
                customClass="col-4"
                label="Reemplazar imagen"
                value={value}
                setValue={(name, file) => onChange(file)} 
                serverBasePath={process.env.REACT_APP_IMG_CALENDAR}
              />
            )}
          />
        </div>
        <button type="submit" className="btn btn-une btn-submit">
          Subir imagen
        </button>
      </form>
      <div style={{ height: '1px', backgroundColor: '#ccc', width: '100%', margin: '1rem 0' }} />
      <p style={{ color: '#777' }}>
        La imagen de calendario debe mostrar las fechas en que el subsidio de camiones para estudiantes estará vigente.
        También se puede utilizar para informar sobre otros temas relacionados a credencialización.
      </p>
    </div>
  );
};

FormCalendar.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
  alert: PropTypes.func,
};

FormCalendar.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
  alert: f => f,
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
    alert: alert => dispatch(actions.alert(alert)),
  }),
)(FormCalendar);
