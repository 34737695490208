import React from 'react';
import PropTypes from 'prop-types';
import jwt from 'jsonwebtoken';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import useClickOutside from '#hooks/useClickOutside';
import { getToken, logout } from '#utils/authService';
import styles from './panelNavbar.module.css';

const logo = require('#assets/images/logo.png');

const PanelNavbar = ({ onToggleClick }) => {
  const [dropdown, setDropdown] = React.useState(false);
  const toggleDropdown = () => setDropdown(prevState => !prevState);

  const clickRef = useClickOutside(dropdown, toggleDropdown);

  const token = React.useMemo(() => {
    const token = getToken();
    return jwt.decode(token);
  }, []);

  return (
    <div data-testid="PanelNavbar">
      <nav data-testid="component-panel-navbar" className={`navbar navbar-expand-lg navbar-light bg-light ${styles['navbar']}`}>
        <button
          type="button"
          className={`btn btn-link ${styles['toggle']}`}
          onClick={onToggleClick}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <img src={logo} alt="Logo" className={styles['logo']} />

        <div className="collapse navbar-collapse">
          <ul className="navbar-nav ml-auto">
            <li className={`nav-item dropdown ${styles['nav-item']}`}>
              <button type="button" className={`btn btn-link nav-link dropdown-toggle ${styles['nav-link']} ${styles['dropdown-toggle']}`} onClick={toggleDropdown}>
                <FontAwesomeIcon icon={faUserCircle} style={{ marginRight: '.5rem' }} />
                <span>Bienvenido, {token.name}</span>
              </button>
              <div ref={clickRef} className={`dropdown-menu ${dropdown && 'show'} ${styles['dropdown-menu']}`}>
                <a className="dropdown-item" href="/panel/monitor">Ir a página principal</a>
                <div className="dropdown-divider" />
                <Link to="/auth/inicio" className="btn btn-link" onClick={logout}>
                  <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '0.5rem' }} />
                  Cerrar sesión
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

PanelNavbar.propTypes = {
  onToggleClick: PropTypes.func,
};

PanelNavbar.defaultProps = {
  onToggleClick: f => f,
};

export default PanelNavbar;
