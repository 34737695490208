import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import moment from 'moment';
import 'moment/locale/es';
import useDynamicTable from '#hooks/useDynamicTable';
import Select from '../../custom/Select';
import DataTable from '../DataTable';
import { Link, Route } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';
import { useForm, Controller, useWatch } from 'react-hook-form';

const TableBusses = ({ loading }) => {
  const { control, formState: { errors } } = useForm();
  const [rawRows, setRawRows] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [routes, setRoutes] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');

  const watchCity = useWatch({ control, name: "id_city" });
  const watchRoute = useWatch({ control, name: "id_route" });

  const getData = React.useCallback(
    async () => {
      const [resp, error] = await of(
        roAPI.get('/vehicles/custom', {
          params: {
            nocache: true,
          },
        }),
      );
      if (!error) {
        setRawRows(resp.rows);
      }
    },
    [],
  );

  React.useEffect(() => {
    async function getCities() {
      loading.set();
      const [res] = await of(
        roAPI.get('/cities', {
          params: { disabled: false },
        }),
      );
      setCities(res.rows);
      loading.stop();
    }
    getCities();
  }, [loading]);

  React.useEffect(() => {
    async function getRoutes() {
      try {
        loading.set();
        const res = await roAPI.get(`/routes/city/${watchCity}`, {
          params: {
            nocache: true,
            disabled: '0,1',
          },
        });
        setRoutes(res);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
  
    if (watchCity) {
      getRoutes();
    }
  }, [watchCity, loading]);

  const rows = React.useMemo(() => {
    let filteredRows = rawRows;

    if (watchCity) {
      filteredRows = filteredRows.filter(row => row.city.id_city === watchCity);
    }

    if (watchRoute) {
      filteredRows = filteredRows.filter(row => row.route.id_route === watchRoute);
    }

    if (searchTerm) {
      filteredRows = filteredRows.filter(row =>
        row.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.city.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.route.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    return filteredRows.map(row => ({
      id: row.id_vehicle,
      code: row.code,
      city_name: row.city.name,
      route_name: row.route.name,
      status: row.disabled,
      date: row.updated_at,
      options: { id: row.id_vehicle },
    }));
  }, [rawRows, watchCity, watchRoute, searchTerm]);

  const columnsConfig = React.useMemo(
    () => ({
      id: val => <span>{val}</span>,
      code: val => <span>{val}</span>,
      city_name: val => <span>{val}</span>,
      route_name: val => <span>{val}</span>,
      status: val => (
        <span className={`badge table-badge ${!val ? 'badge-success' : 'badge-warning'}`}>
          {!val ? 'Activo' : 'Inactivo'}
        </span>
      ),
      date: val => <span>{moment(val).format('DD MMMM YYYY HH:mm')}</span>,
      options: {
        render: function col(val) {
          return (
            <>
              <Link
                className="btn btn-sm btn-primary"
                to={{ pathname: `/panel/autobuses/gestionar/autobus/${val.id}` }}
                style={{ marginRight: '.25rem' }}
              >
                <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
                <span>Editar</span>
              </Link>
            </>
          );
        },
        style: {
          whiteSpace: 'nowrap',
        },
      },
    }),
    [],
  );

  const {
    currentPage,
    size,
    handlePageChange,
    handleSizeChange,
    handleSearch,
    loadItems
  } = useDynamicTable(getData, 1, 10);

  React.useEffect(() => {
    handleSearch(searchTerm);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <div>
      <div className="section-header" style={{ marginBottom: '15px' }}>
        <h5>Gestión de autobuses</h5>
        <Route
          exact
          path={`/panel/autobuses/gestionar/`}
          render={() => (
            <Link to="/panel/autobuses/gestionar/autobus" className="btn btn-primary">
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '.5rem' }} />
              Agregar autobús
            </Link>
          )}
        />
      </div>

      <div className="row" style={{ width: '100%', marginBottom: '15px' }}>
        <div className="col-12 col-md-4">
          <Controller
            name="id_city"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                {...field}
                customClass="form-select w-100 mb-2"
                placeholder="Selecciona ciudad"
                options={cities.map(x => ({ value: x.id_city, label: x.name }))}
                error={errors.id_city}
                onChange={value => {
                  field.onChange(value);
                  setRoutes([]);
                }}
              />
            )}
          />
        </div>
        <div className="col-12 col-md-4">
          <Controller
            name="id_route"
            control={control}
            rules={{ required: 'Selecciona una ruta' }}
            render={({ field }) => (
              <Select
                {...field}
                customClass="form-select w-100"
                placeholder="Selecciona una ruta"
                options={routes.map(x => ({ value: x.id_route, label: x.name }))}
                error={errors.id_route}
                onChange={value => {
                  field.onChange(value);
                }}
                extraProps={{
                  disabled: !watchCity,
                }}
              />
            )}
          />
        </div>
      </div>

      <DataTable
        locale="es"
        columns={['ID', 'Autobús', 'Ciudad', 'Ruta', 'Activo', 'Última actualización', 'Opciones']}
        list={rows.slice((currentPage - 1) * size, currentPage * size)}
        currentPage={currentPage}
        size={size}
        totalRows={rows.length}
        handlePageChange={handlePageChange}
        handleSizeChange={handleSizeChange}
        handleSearch={setSearchTerm}
        columnsConfig={columnsConfig}
        tableClass="table table-striped table-sm"
        tableWrapperClass="table-une"
        noRowsMessage="No existen autobuses"
        loadItems={loadItems}
      />
    </div>
  );
};

TableBusses.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

TableBusses.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(TableBusses);
