import React from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import useClickOutside from '#hooks/useClickOutside';
import styles from './colorPicker.module.css';

const ColorPicker = React.forwardRef(({ customClass, name, label, value, onChange, error }, ref) => {
  const [show, setShow] = React.useState(false);
  const toggleShow = () => setShow(prevState => !prevState);

  const clickRef = useClickOutside(show, toggleShow);

  const handleColorChange = color => {
    onChange(color.hex); 
  };

  return (
    <div ref={clickRef} className={`wafoformcolorpicker form-group wafo-input ${styles['wrapper']} ${customClass}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className="row">
        <div className="col-8">
          <input
            ref={ref}  // Aquí utilizamos el ref
            type="text"
            className={`form-control ${styles['input']} ${error ? 'is-invalid' : ''}`}
            placeholder="#FFFFFF"
            value={value}
            onChange={f => f}
            onClick={toggleShow}
            readOnly
          />
        </div>
        <div className="col-4">
          <div className={styles['color-wrapper']}>
            <div className={styles['color']} style={{ backgroundColor: value }} onClick={toggleShow} />
          </div>
        </div>
      </div>
      {show && (
        <div className={styles['picker']}>
          <SketchPicker color={value} onChangeComplete={handleColorChange} />
        </div>
      )}
      {error && <div className="invalid-feedback">{error.message}</div>}
    </div>
  );
});

ColorPicker.propTypes = {
  customClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired, 
  error: PropTypes.object,
};

ColorPicker.defaultProps = {
  customClass: '',
  label: undefined,
  value: '',
  error: null,
};

ColorPicker.displayName = 'ColorPicker';

export default ColorPicker;