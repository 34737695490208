import React from 'react';
import jwt from 'jsonwebtoken';
import PropTypes from 'prop-types';
import { BrowserView, MobileView } from 'react-device-detect';
import { Link, Switch, Route, useHistory } from 'react-router-dom';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import AlertsHOC from '#components/HOCs/alertsHOC/alertsHOC';
import FormLogin from '#components/forms/formLogin/formLogin';
import FormRequestPassword from '#components/forms/formRequestPassword/formRequestPassword';
import FormChangePassword from '#components/forms/formChangePassword/formChangePassword';
import FormRequestActivate from '#components/forms/formRequestActivate/formRequestActivate';
import ActivateAccount from '#components/random/auth/activateAccount';
import TextDivider from '#components/random/textDivider/textDivider';
import FacebookAuth from '#components/forms/social/facebookAuth';
import GoogleAuth from '#components/forms/social/googleAuth';
import { storeToken, getVerifiedToken } from '#utils/authService';
import { roAPI } from '#utils/axiosAPI';
import styles from './login.module.css';

const logo = require('#assets/images/logo.png');

const Login = ({ alert }) => {
  const history = useHistory();

  const handleRedirects = React.useCallback((value, type = 'token') => {
    switch (type) {
      case 'token': {
        const decoded = jwt.decode(value);
        if ([1, 3].includes(decoded.type)) {
          history.push('/panel');
        } else {
          sessionStorage.removeItem('token');
          alert({
            type: 'danger',
            title: 'Acceso Denegado',
            message: 'Solo usuarios administradores.',
            timer: 3000,
          });
        }
        break;
      }
      case 'redirect':
        history.push(value);
        break;
      default:
        break;
    }
  }, [alert, history]);

  React.useEffect(() => {
    async function checkToken() {
      const verified = await getVerifiedToken();
      if (verified) {
        handleRedirects(verified.token);
      }
    }
    checkToken();
  }, [handleRedirects]);

  const onFacebookLogin = async facebook => {
    try {
      const resp = await roAPI.post(
        '/auth/facebook/login',
        {},
        {
          headers: {
            Authorization: `Bearer ${facebook.accessToken}`,
          },
        },
      );
      storeToken(resp.token);
      handleRedirects(resp.token);
    } catch (error) {
      //
    }
  };

  const onGoogleLogin = async google => {
    if (!google.error) {
      try {
        const resp = await roAPI.post(
          '/auth/google/login',
          {},
          {
            headers: {
              Authorization: `Bearer ${google.accessToken}`,
            },
          },
        );
        storeToken(resp.token);
        handleRedirects(resp.token);
      } catch (error) {
        //
      }
    }
  };

  const SubtitleComponent = (
    <Switch>
      <Route path="/auth/inicio" render={() => <h1 className="text-center">¡Bienvenido de vuelta!</h1>} />
      <Route path="/auth/registro" render={() => <h1 className="text-center">¡Registrate en UNE!</h1>} />
      <Route path="/auth/recuperar" render={() => <h1 className="text-center">Recupera tu contraseña</h1>} />
      <Route path="/auth/change/password/:email/:code" render={() => <h1 className="text-center">Introduce tu nueva contraseña</h1>} />
      <Route path="/auth/activate" render={() => <h1 className="text-center">Reenviar correo de activación</h1>} />
    </Switch>
  );

  const FormsComponent = (
    <>
      <Switch>
        <Route path="/auth/inicio" render={() => <FormLogin onAfterSubmit={handleRedirects} />} />
        <Route path="/auth/recuperar" render={() => <FormRequestPassword onAfterSubmit={handleRedirects} />} />
        <Route path="/auth/change/password/:email/:code" render={() => <FormChangePassword onAfterSubmit={handleRedirects} />} />
        <Route path="/auth/activate/:email/:code" render={() => <ActivateAccount onAfterSubmit={handleRedirects} />} />
        <Route path="/auth/activate" render={() => <FormRequestActivate onAfterSubmit={handleRedirects} />} />
      </Switch>

      <ul className={styles['link-list']}>
        <Switch>
          <Route
            path="/auth/inicio"
            render={() => (
              <>
                <li>
                  <Link to="/auth/recuperar">Recupera tu contraseña</Link>
                </li>
              </>
            )}
          />
          <Route
            path="/auth/activate"
            render={() => (
              <li>
                <Link to="/auth/inicio">Volver a inicio de sesión</Link>
              </li>
            )}
          />
          <Route
            path="*"
            render={() => (
              <li>
                <Link to="/auth/inicio">¿Ya tienes una cuenta? Inicia sesión</Link>
              </li>
            )}
          />
        </Switch>
      </ul>

      <TextDivider text="Redes sociales" />

      <FacebookAuth text="Conectar con Facebook" onFacebookLogin={onFacebookLogin} />

      <GoogleAuth text="Conectar con Google" onGoogleLogin={onGoogleLogin} />
    </>
  );

  return (
    <div className={styles['login']}>
      <BrowserView>
        <div className={`container ${styles['height100']} ${styles['padding']}`}>
          <div className={`row justify-content-center align-items-center ${styles['height100']}`}>
            <div className="col-12 col-sm-12 col-md-6">
              <div className="card mirai-shadow">
                <div className={`card-body ${styles['card-body']}`}>
                  <img src={logo} alt="Logo" className={styles['logo']} />

                  {SubtitleComponent}

                  {FormsComponent}
                </div>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className={`card ${styles['mobile-card']}`}>
          <div className={`card-body mirai-shadow ${styles['mobile-body']}`}>
            <img src={logo} alt="Logo" className={styles['logo']} />
            {SubtitleComponent}
          </div>
          <div className={styles['otro-body']}>{FormsComponent}</div>
        </div>
      </MobileView>
    </div>
  );
};

Login.propTypes = {
  alert: PropTypes.func,
};

Login.defaultProps = {
  alert: f => f,
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    alert: alert => dispatch(actions.alert(alert)),
  }),
)(AlertsHOC(Login));
