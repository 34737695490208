import React from 'react';
import PropTypes from 'prop-types';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';

const WafoDraftJS = React.forwardRef(({
  customClass,
  name,
  label,
  value,
  onChange,
  toolbarClassName,
  wrapperClassName,
  editorClassName,
}, ref) => {
  const handleEditorChange = editorState => {
    onChange(editorState);
  };

  return (
    <div className={`wafoformdraftjs form-group wafo-input ${customClass}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <Editor
        ref={ref} // Añadimos el ref aquí
        editorState={value}
        toolbarClassName={toolbarClassName}
        wrapperClassName={wrapperClassName}
        editorClassName={editorClassName}
        onEditorStateChange={handleEditorChange}
      />
    </div>
  );
});

WafoDraftJS.propTypes = {
  customClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.instanceOf(EditorState),
  onChange: PropTypes.func.isRequired, 
  toolbarClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  editorClassName: PropTypes.string,
};

WafoDraftJS.defaultProps = {
  customClass: '',
  label: undefined,
  value: EditorState.createEmpty(),
  toolbarClassName: 'draftjsToolbarWrapper',
  wrapperClassName: 'draftjsWrapper',
  editorClassName: 'draftjsEditorWrapper',
};

WafoDraftJS.displayName = 'WafoDraftJS';

export default WafoDraftJS;
