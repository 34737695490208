import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './timePicker.module.css';

const TimePicker = React.forwardRef(({ label, customClass, value, onChange, disabled, error }, ref) => {
  const time = useMemo(() => {
    const [hours, minutes] = value.split(':');
    return { hours: hours || '', minutes: minutes || '' };
  }, [value]);

  const handleOnChange = event => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    const {
      target: { name: inputName, value: inputValue },
    } = event;

    let newTime;
    switch (inputName) {
      case 'picker-hours': {
        let h = parseInt(inputValue, 10);
        h = isNaN(h) ? '' : h > 23 ? '23' : h < 10 ? `0${h}` : h.toString();
        newTime = `${h}:${time.minutes || '00'}:00`;
        break;
      }
      case 'picker-minutes': {
        let m = parseInt(inputValue, 10);
        m = isNaN(m) ? '' : m > 59 ? '59' : m < 10 ? `0${m}` : m.toString();
        newTime = `${time.hours || '00'}:${m}:00`;
        break;
      }
      default:
        break;
    }

    if (newTime) {
      onChange(newTime);
    }
  };

  return (
    <div className={`form-group ${customClass}`}>
      {label && <label htmlFor="picker-time">{label}</label>}
      <div className={styles['wrapper']}>
        <input
          ref={ref}
          type="number"
          name="picker-hours"
          className={`form-control no-arrows ${styles['input-hours']} ${error ? 'is-invalid' : ''}`}
          placeholder="HH"
          min="0"
          max="23"
          autoComplete="off"
          value={time.hours}
          onChange={handleOnChange}
          onClick={event => event.target.select()}
          disabled={disabled}
        />
        <span className={`${styles['input-colons']} ${disabled && styles['disabled']}`}>:</span>
        <input
          type="number"
          name="picker-minutes"
          className={`form-control no-arrows ${styles['input-minutes']} ${error ? 'is-invalid' : ''}`}
          placeholder="MM"
          min="0"
          max="59"
          autoComplete="off"
          value={time.minutes}
          onChange={handleOnChange}
          onClick={event => event.target.select()}
          disabled={disabled}
        />
      </div>
      {error?.message && (
        <div className="invalid-feedback d-block">
          {error.message}
        </div>
      )}
    </div>
  );
});

TimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  customClass: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.object, 
};

TimePicker.defaultProps = {
  label: '',
  customClass: '',
  value: '',
  disabled: false,
  error: null,
};

TimePicker.displayName = 'TimePicker';

export default TimePicker;
