import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import moment from 'moment';
import 'moment/locale/es';
import confirmModal from '#components/modals/confirm/confirm';
import useUpdateCities from '#hooks/useUpdateCities';
import useDynamicTable from '#hooks/useDynamicTable';
import DataTable from '../DataTable'; 
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faMoneyBill, faIdCard, faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';

const TableCredentialsLocations = ({ loading, cities, updateCities }) => {
  const getData = React.useCallback(
    async ({ size, page, search }) => {
      loading.set();
      const [resp, error] = await of(
        roAPI.get('/rps/pagination', {
          params: {
            limit: size,
            page,
            nocache: true,
            ...(search && { search }),
          },
        }),
      );
      loading.stop();

      if (!error) {
        const rows = resp.rows.map(row => ({
          id: row.id_rp,
          type: row.type,
          city: row.id_city,
          name: row.name,
          address: row.address,
          date: row.updated_at,
          options: { id: row.id_rp, rp: row, disabled: row.disabled },
        }));
        return { rows, count: resp.count };
      }
      return { rows: [], count: 0 };
    },
    [loading],
  );

  const {
    count,
    list,
    currentPage,
    size,
    handlePageChange,
    handleSizeChange,
    handleSearch,
    loadItems,
  } = useDynamicTable(getData, 1, 10);

  useUpdateCities(updateCities, loading, false);

  const handleDelete = React.useCallback(
    id => {
      async function deletePOI() {
        loading.set();
        const [, err] = await of(roAPI.delete(`/rps/${id}`));
        if (!err) {
          loadItems(); 
        }
        loading.stop();
      }
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            También será eliminado de las líneas donde aparece.
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: deletePOI,
          },
        ],
      });
    },
    [loading, loadItems],
  );

  const handleDisable = React.useCallback(
    (id, val) => {
      async function disableLocation() {
        loading.set();
        const [, err] = await of(
          roAPI.put(`/rps/${id}`, {
            disabled: !val,
          }),
        );
        if (!err) {
          loadItems(); 
        }
        loading.stop();
      }
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            {!val ? 'La ubicación no se mostrará en el mapa.' : 'La ubicación se mostrará en el mapa.'}
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: disableLocation,
          },
        ],
      });
    },
    [loading, loadItems],
  );

  const columnsConfig = React.useMemo(
    () => ({
      id: val => <span>{val}</span>,
      type: val => (
        <span className="badge badge-secondary" style={{ padding: '.25em 1rem' }}>
          {val === 1 ? <FontAwesomeIcon icon={faMoneyBill} /> : <FontAwesomeIcon icon={faIdCard} />}
        </span>
      ),
      city: val => {
        const city = cities.find(x => x.id_city === val);
        return <span>{city ? city.name : 'Sin ciudad'}</span>;
      },
      name: val => <span>{val}</span>,
      address: val => <span>{val}</span>,
      date: val => <span>{moment(val).format('DD MMMM YYYY HH:mm')}</span>,
      options: val => (
        <div className="d-flex justify-content-center align-items-center gap-2">
          <button
            type="button"
            className={`btn btn-sm ${!val.disabled ? 'btn-success' : 'btn-warning'}`}
            onClick={() => handleDisable(val.id, val.disabled)}
            style={{ width: '40px', height: '40px' }}
          >
            <FontAwesomeIcon icon={!val.disabled ? faToggleOn : faToggleOff} />
          </button>
          <Link
            className="btn btn-sm btn-primary d-flex align-items-center justify-content-center"
            to={{ pathname: `/panel/credencializacion/ubicaciones/rp/${val.id}`, state: { rp: val.rp } }}
            style={{ width: '40px', height: '40px' }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Link>
          <button
            type="button"
            className="btn btn-sm btn-danger d-flex align-items-center justify-content-center"
            onClick={() => handleDelete(val.id)}
            style={{ width: '40px', height: '40px' }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </div>
      ),
    }),
    [cities, handleDisable, handleDelete],
  );
  

  return (
    <div>
      <DataTable
        locale="es"
        columns={['ID', 'Tipo', 'Ciudad', 'Nombre', 'Dirección', 'Última actualización', 'Opciones']}
        list={list}
        currentPage={currentPage}
        size={size}
        totalRows={count}
        handlePageChange={handlePageChange}
        handleSizeChange={handleSizeChange}
        handleSearch={handleSearch}
        columnsConfig={columnsConfig}
        tableClass="table table-striped table-sm"
        tableWrapperClass="table-une"
        noRowsMessage="Sin puntos de credencialización o recarga"
        loadItems={loadItems}
      />
    </div>
  );
};

TableCredentialsLocations.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
  cities: PropTypes.array,
  updateCities: PropTypes.func,
};

TableCredentialsLocations.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
  cities: [],
  updateCities: f => f,
};

export default reduxConnect(
  state => ({
    cities: state.cities,
  }),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(TableCredentialsLocations);
