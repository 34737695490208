import React from 'react';
import of from 'await-of';
import moment from 'moment';
import 'moment/locale/es';
import confirmModal from '#components/modals/confirm/confirm';
import useDynamicTable from '#hooks/useDynamicTable';
import DataTable from '../DataTable';
import useLoading from '#hooks/redux/useLoading';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faKey, faToggleOn, faToggleOff, faIdCard, faList } from '@fortawesome/free-solid-svg-icons';
import { roAPI } from '#utils/axiosAPI';

const TableUsers = () => {
  const loading = useLoading();

  // Fetch data using useDynamicTable hook
  const getData = React.useCallback(
    async ({ size, page, search }) => {
      loading.set();
      const [resp, error] = await of(
        roAPI.get('/admin/users', {
          params: {
            limit: size,
            page,
            nocache: true,
            ...(search && { search }),
            order: '-id_user',
          },
        }),
      );
      loading.stop();

      if (!error) {
        const rows = resp.rows.map(row => ({
          id: row.id_user,
          type: row.type,
          card: { cards: row.user_cards, curp: row.curp },
          social: {
            fb: row.facebook_id,
            go: row.google_id,
          },
          name: `${row.name} ${row.last_name}`,
          contact: { email: row.email, phone: row.phone_number },
          date: row.created_at,
          options: {
            id: row.id_user,
            disabled: row.disabled,
            user: row,
          },
        }));
        return { rows, count: resp.count };
      }

      return { rows: [], count: 0 };
    },
    [loading],
  );

  // Use useDynamicTable hook
  const {
    count,
    list,
    currentPage,
    size,
    handlePageChange,
    handleSizeChange,
    handleSearch,
    loadItems,
  } = useDynamicTable(getData, 1, 10);

  const handleDisable = React.useCallback(
    (id, val) => {
      async function disableUser() {
        loading.set();
        const [, err] = await of(
          roAPI.put(`/admin/users/${id}`, {
            disabled: !val,
          }),
        );
        if (!err) {
          loadItems();
        }
        loading.stop();
      }

      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            {!val ? 'El usuario perderá el acceso al sistema de transporte.' : 'El usuario recuperará el acceso al sistema de transporte.'}
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: disableUser,
          },
        ],
      });
    },
    [loading, loadItems],
  );

  // Column configuration
  const columnsConfig = React.useMemo(
    () => ({
      id: val => <span>{val}</span>,
      type: val => (
        <span className="badge badge-secondary" style={{ padding: '.2em .75em', fontSize: '1em', display: 'inline-block' }}>
          <FontAwesomeIcon icon={val === 1 ? faKey : faUser} />
        </span>
      ),
      card: val => {
        const card = val.cards.length ? val.cards[0] : null;
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className={`badge ${card || val.curp ? 'badge-success' : 'badge-secondary'}`} style={{ padding: '.5em 1em' }}>
              <FontAwesomeIcon icon={faIdCard} />
            </span>
          </div>
        );
      },
      social: val => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className={`badge ${val.fb ? 'badge-primary' : 'badge-secondary'}`} style={{ padding: '.5em 1em', marginRight: '.5rem' }}>
            F
          </span>
          <span className={`badge ${val.go ? 'badge-light' : 'badge-secondary'}`} style={{ padding: '.5em 1em', marginRight: '.5rem' }}>
            G
          </span>
        </div>
      ),
      name: val => <span>{val}</span>,
      contact: val => (
        <>
          {val.email && (
            <>
              <span>{val.email}</span>
              <br />
            </>
          )}
          {val.phone && <span>{val.phone}</span>}
          {!val.email && !val.phone && <span>Sin información</span>}
        </>
      ),
      date: val => <span>{moment(val).format('DD MMMM YYYY HH:mm')}</span>,
      options: val => (
        <>
          <button
            type="button"
            className={`btn btn-sm ${!val.disabled ? 'btn-success' : 'btn-danger'}`}
            onClick={() => handleDisable(val.id, val.disabled)}
            style={{ padding: '.25rem 1rem' }}
          >
            <FontAwesomeIcon icon={!val.disabled ? faToggleOn : faToggleOff} />
          </button>
          <Link
            className="btn btn-sm btn-secondary"
            to={{ pathname: `/panel/users/user/${val.id}`, state: { user: val.user } }}
          >
            <FontAwesomeIcon icon={faList} style={{ marginRight: '.5rem' }} />
            <span>Detalles</span>
          </Link>
        </>
      ),
    }),
    [handleDisable],
  );

  return (
    <div>
      <DataTable
        columns={['ID', 'Tipo', '', '', 'Nombre', 'Contacto', 'Registro', 'Opciones']}
        list={list}
        currentPage={currentPage}
        size={size}
        totalRows={count}
        handlePageChange={handlePageChange}
        handleSizeChange={handleSizeChange}
        handleSearch={handleSearch}
        columnsConfig={columnsConfig}
        tableClass="table table-striped table-sm"
        tableWrapperClass="table-une"
        noRowsMessage="Sin usuarios"
        loadItems={loadItems}
      />
    </div>
  );
};

export default TableUsers;
