import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import confirmModal from '#components/modals/confirm/confirm';
import styles from './configurations.module.css';
import useDynamicTable from '#hooks/useDynamicTable';
import DataTable from '../DataTable';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOn, faToggleOff, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const TableIcons = ({ loading }) => {
  const getData = React.useCallback(
    async ({ size, page, search }) => {
      try {
        loading.set();
        const resp = await roAPI.get(`/configurations/icons`, {
          params: {
            limit: size,
            page,
            ...(search && { search }),
          },
        });

        loading.stop();
        
        return {
          rows: resp.rows,
          count: resp.count,
        };
      } catch (error) {
        loading.stop();
        return { rows: [], count: 0 };
      }
    }, [loading]);

  const {
    count,
    list,
    currentPage,
    size,
    handlePageChange,
    handleSizeChange,
    handleSearch,
    loadItems, 
  } = useDynamicTable(getData, 1, 10);
  
  // Map the data after obtaining the list
  const rows = list.map(row => ({
    id: row.id_icon,
    status: row.disabled,
    icon: {
      icon: row.image_icon,
      color: row.primary_color,
    },
    order: row.order,
    name: row.name,
    date: row.updated_at,
    options: row,
  }));

  const handleDisable = React.useCallback(
    (id, val) => {
      async function disableType() {
        try {
          loading.set();
          await roAPI.put(`/configurations/icons/${id}`, {
            disabled: !val,
          });
          loadItems();
          loading.stop();
        } catch (error) {
          loading.stop();
        }
      }
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            {!val
              ? 'El tipo no podrá ser utilizado en el sistema de transporte.'
              : 'El tipo podrá ser utilizado en el sistema de transporte.'}
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: disableType,
          },
        ],
      });
    },
    [loading, loadItems],
  );

  const handleDelete = React.useCallback(
    id => {
      async function deleteType() {
        try {
          loading.set();
          await roAPI.delete(`/configurations/icons/${id}`);
          loadItems();
          loading.stop();
        } catch (error) {
          loading.stop();
        }
      }
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            Una vez eliminado, no se podrán configurar unidades con este tipo.
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: deleteType,
          },
        ],
      });
    },
    [loading, loadItems],
  );

  const columnsConfig = React.useMemo(
    () => ({
      id: val => <span>{val}</span>,
      status: val => (
        <span className={`badge table-badge ${!val ? 'badge-success' : 'badge-danger'}`}>
          {!val ? 'Activo' : 'Inactivo'}
        </span>
      ),
      icon: val => (
        <div className={styles['icono-wrapper']} style={{ backgroundColor: val.color }}>
          <img className={styles['icono']} src={`${process.env.REACT_APP_IMG_BUSICON}/${val.icon}`} alt="icon" />
        </div>
      ),
      order: val => <span>{val}</span>,
      name: val => <span>{val}</span>,
      date: val => <span>{moment(val).format('DD MMMM YYYY HH:mm')}</span>,
      options: val => (
        <React.Fragment>
          <button
            type="button"
            className={`btn btn-sm ${!val.disabled ? 'btn-success' : 'btn-warning'}`}
            style={{ padding: '.25rem 1rem', marginRight: '0.25rem' }}
            onClick={() => handleDisable(val.id_icon, val.disabled)}
          >
            <FontAwesomeIcon icon={!val.disabled ? faToggleOn : faToggleOff} />
          </button>
          <Link
            className="btn btn-sm btn-primary"
            to={{ pathname: `/panel/configuracion/iconos/icono/${val.id_icon}`, state: { type: val } }}
            style={{ marginRight: '0.25rem' }}
          >
            <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
            <span>Editar</span>
          </Link>
          <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={() => handleDelete(val.id_icon)}
            disabled={val.protected}
          >
            <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: '.5rem' }} />
            Eliminar
          </button>
        </React.Fragment>
      ),
    }),
    [handleDisable, handleDelete],
  );

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <h5>Tipos de autobus</h5>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <DataTable
            columns={['ID', 'Estado', 'Icono', 'Prioridad', 'Nombre', 'Actualizado', 'Opciones']}
            list={rows} 
            currentPage={currentPage}
            size={size}
            totalRows={count}
            handlePageChange={handlePageChange}
            handleSizeChange={handleSizeChange}
            handleSearch={handleSearch}
            columnsConfig={columnsConfig}
            tableClass="table table-striped table-sm"
            tableWrapperClass="table-responsive"
            noRowsMessage="Sin tipos de autobus para mostrar"
            loadItems={loadItems}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

TableIcons.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

TableIcons.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(TableIcons);
