import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import ColorPicker from '#components/forms/customForms/colorPicker/colorPicker';
import ImagePicker from '#components/forms/customForms/imagePicker/imagePicker';
import useUpdateCities from '#hooks/useUpdateCities';
import Input from '../../custom/Input';
import Select from '../../custom/Select';
import WafoDraftJS from '../customForms/wafoDraftJS/wafoDraftJS';
import { useForm, Controller } from 'react-hook-form';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams } from 'react-router-dom';
import { roAPI } from '#utils/axiosAPI';
import { information_types } from '#utils/utils';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';

const FormCredentialsInformation = ({ loading, alert, cities, updateCities, location }) => {
  const { register, control, handleSubmit, setValue, formState: { errors } } = useForm();

  const information = useMemo(() => {
    if (location.state && location.state.information) {
      const rawData = markdownToDraft(location.state.information.description);
      const contentState = convertFromRaw(rawData);
      const newEditorState = EditorState.createWithContent(contentState);

      return {
        ...location.state.information,
        description: newEditorState,
      };
    }
    return {};
  }, [location.state]);

  const history = useHistory();
  const { id: editing_id } = useParams();
  useUpdateCities(updateCities, loading, false);

  useEffect(() => {
    if (information) {
      setValue('id_city', information.id_city);
      setValue('type', information.type);
      setValue('title', information.title);
      setValue('order', information.order);
      setValue('bar_color', information.bar_color || '#FFFFFF');
      setValue('text_color', information.text_color || '#FFFFFF');
      setValue('image_icon', information.image_icon || '');
      setValue('description', information.description);
    }
  }, [information, setValue]);

  const onSubmit = async (values) => {
    try {
      loading.set();
      const rawContentState = convertToRaw(values.description.getCurrentContent());
      const markdown = draftToMarkdown(rawContentState);

      const formModel = new FormData();
      formModel.append('id_city', values.id_city);
      if (!editing_id) {
        formModel.append('type', values.type);
      }
      formModel.append('title', values.title);
      formModel.append('bar_color', values.bar_color);
      formModel.append('text_color', values.text_color);
      formModel.append('order', values.order);
      formModel.append('description', markdown);
      if (typeof values.image_icon !== 'string' && values.image_icon) {
        formModel.append('image_icon', values.image_icon);
      }

      editing_id
        ? await roAPI.put(`/credentials/instructions/${editing_id}`, formModel)
        : await roAPI.post('/credentials/instructions', formModel);

      history.push('/panel/credencializacion/informacion');
      alert({
        type: 'success',
        title: '¡Información añadida!',
        message: 'Se ha añadido exitosamente la información de credencialización.',
        timer: 3000,
      });
      loading.stop();
    } catch (error) {
      loading.stop();
      console.error(error);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <button type="button" className={`btn btn-link back`} onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
            <span>Regresar</span>
          </button>
        </div>
      </div>

      <form id="formInformation" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12 col-md-4">
            <Controller
              name="id_city"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  customClass="mb-2"
                  placeholder="Selecciona ciudad"
                  label="Ciudad"
                  options={cities.map(x => ({ value: x.id_city, label: x.name }))}
                  error={errors.id_city}
                />
              )}
              rules={{ required: 'Selecciona una ciudad' }}
            />
          </div>

          <div className="col-12 col-md-4">
            <Controller
              name="type"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Selecciona tipo"
                  label="Tipo de información"
                  customClass="mb-2"
                  options={information_types.map(it => ({
                    value: it.id,
                    label: it.name,
                  }))}
                  error={errors.type}
                  disabled={!!editing_id}
                />
              )}
              rules={{ required: 'Selecciona un tipo' }}
            />
          </div>

          <div className="col-12 col-md-4">
            <Input
              label="Título"
              placeholder="Inserte título"
              customClass="mb-2"
              {...register('title', { required: 'Este campo es requerido' })}
              error={errors.title}
            />
          </div>

          <div className="col-12 col-md-4">
            <Input
              type="number"
              label="Prioridad"
              placeholder="0"
              customClass="mb-2"
              {...register('order', { required: 'Este campo es requerido', min: 1, max: 10 })}
              error={errors.order}
            />
          </div>

          <div className="col-12 col-md-4">
            <Controller
              name="bar_color"
              control={control}
              rules={{ required: 'Este campo es requerido' }}
              render={({ field }) => (
                <ColorPicker
                  {...field}
                  label="Color de barra"
                  error={errors.bar_color}
                />
              )}
            />
          </div>

          <div className="col-12 col-md-4">
            <Controller
              name="text_color"
              control={control}
              rules={{ required: 'Este campo es requerido' }}
              render={({ field }) => (
                <ColorPicker
                  {...field}
                  label="Color de texto"
                  error={errors.text_color}
                />
              )}
            />
          </div>

          <div className="col-12 col-md-4">
            <Controller
              name="image_icon"
              control={control}
              defaultValue=""
              render={({ field: { value, onChange } }) => (
                <ImagePicker
                  name="image_icon"
                  label="Icono de información"
                  value={value}
                  setValue={(_, file) => onChange(file)}
                  serverBasePath={process.env.REACT_APP_IMG_CREDENTIALS}
                />
              )}
            />
          </div>

          <div className="col-12">
            <Controller
              name="description"
              control={control}
              defaultValue={information.description || EditorState.createEmpty()}
              render={({ field }) => (
                <WafoDraftJS
                  {...field}
                  label="Contenido"
                />
              )}
            />
          </div>
        </div>

        <div className="row justify-content-center">
          <button type="submit" form="formInformation" className="btn btn-une btn-submit">
            Guardar cambios
          </button>
        </div>
      </form>
    </div>
  );
};

FormCredentialsInformation.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
  alert: PropTypes.func,
  cities: PropTypes.array,
  updateCities: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.any,
  }),
};

FormCredentialsInformation.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
  alert: f => f,
  cities: [],
  updateCities: f => f,
  location: {
    state: {},
  },
};

export default reduxConnect(
  state => ({
    cities: state.cities,
  }),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
    alert: alert => dispatch(actions.alert(alert)),
  }),
)(FormCredentialsInformation);
