import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import BooleanButtons from '../customForms/booleanButtons/booleanButtons';
import styles from './formLine.module.css';
import TimePicker from '#components/forms/customForms/timePicker/timePicker';
import ColorPicker from '../customForms/colorPicker/colorPicker';
import ImagePicker from '../customForms/imagePicker/imagePicker';
import MultiSelect from '../customForms/multiSelect/multiSelect';
import Input from '../../custom/Input';
import Select from '../../custom/Select';
import TextArea from '../../custom/TextArea';
import { useForm, Controller } from 'react-hook-form';
import { roAPI } from '#utils/axiosAPI';

const FormLine = ({ onSubmit, initialValues, cities, onCityChange, tabs, routes, onParentChange, loading, updateRoutes }) => {
  const { register, handleSubmit, control, setValue, formState: { errors }, watch } = useForm();
  const [weekTab, setWeekTab] = useState('week');
  const [busTypes, setBusTypes] = useState([]);
  const [hasConfig, setHasConfig] = useState(false);

  const possibleParents = useMemo(() => {
    const parents = routes.filter(r => r.id_route !== initialValues.id_route);
    parents.unshift({
      id_route: '',
      name: 'Sin línea padre',
    });
    return parents;
  }, [routes, initialValues]);

  useEffect(() => {
    if (initialValues?.route_configurations?.length > 0) {
      setHasConfig(true);
    } else {
      setHasConfig(false);
    }
  }, [initialValues]);

  useEffect(() => {
    if (initialValues) {
      setValue('id_city', initialValues.id_city || '');
      setValue('id_parent_route', initialValues.id_parent_route || '');
      setValue('group_id', initialValues.group_id || '');
      setValue('internal_order', initialValues.internal_order || '');
      setValue('name', initialValues.name || '');
      setValue('description', initialValues.description || '');
      setValue('disabled', initialValues.disabled || false);
      setValue('duration', initialValues?.metadata?.duration || '');
      setValue('distance', initialValues?.metadata?.distance || '');
      setValue('service_units', initialValues?.metadata?.service_units || '');
      setValue('service_frequency', initialValues?.metadata?.service_frequency || '');
      setValue('service_start', initialValues?.metadata?.service_start || '');
      setValue('service_end', initialValues?.metadata?.service_end || '');
      setValue('service_units_saturday', initialValues?.metadata?.service_units_saturday || '');
      setValue('service_frequency_saturday', initialValues?.metadata?.service_frequency_saturday || '');
      setValue('service_start_saturday', initialValues?.metadata?.service_start_saturday || '');
      setValue('service_end_saturday', initialValues?.metadata?.service_end_saturday || '');
      setValue('service_units_sunday', initialValues?.metadata?.service_units_sunday || '');
      setValue('service_frequency_sunday', initialValues?.metadata?.service_frequency_sunday || '');
      setValue('service_start_sunday', initialValues?.metadata?.service_start_sunday || '');
      setValue('service_end_sunday', initialValues?.metadata?.service_end_sunday || '');
      setValue('primary_color', initialValues.primary_color || '#FFFFFF');
      setValue('secondary_color', initialValues.secondary_color || '#FFFFFF');
      setValue('logo', initialValues.logo || '');
      setValue('unit_config', initialValues.unit_config || []);
    }
  }, [initialValues, setValue]);

  useEffect(() => {
    async function getTypes() {
      try {
        loading.set();
        const types = await roAPI.get('/configurations/icons', {
          params: {
            limit: 999,
            page: 1,
          },
        });
        setBusTypes(types.rows);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    getTypes();
  }, [loading]);

  const deleteConfigs = async () => {
    try {
      loading.set();
      const allConfigs = await roAPI.get('/routes/configurations');
      const routeConfigs = Object.entries(allConfigs).reduce((accumulator, [id_route, configs]) => {
        if (initialValues.id_route === id_route) {
          accumulator = configs;
        }
        return accumulator;
      }, []);
      await Promise.all(routeConfigs.map(config => roAPI.delete(`/routes/configurations/${config.id_rc}`)));
      updateRoutes();
      setHasConfig(false);
      setValue('unit_config', []);
      loading.stop();
    } catch (error) {
      loading.stop();
    }
  };

  return (
    <div>
      <form id="formLine" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-12">
          <div className="row" style={{ display: tabs === 'info' ? 'block' : 'none' }}>
            <Controller
              name="id_city"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Ciudad"
                  placeholder="Selecciona la ciudad"
                  options={cities.map(x => ({ value: x.id_city, label: x.name }))}
                  customClass="col-12"
                  error={errors.id_city}
                  onChange={(value) => {
                    field.onChange(value);
                    onCityChange(value);
                  }}
                  disabled={!!initialValues.id_route}
                />
              )}
              rules={{ required: 'Selecciona una ciudad' }}
            />

            <Controller
              name="id_parent_route"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Línea padre"
                  placeholder="Seleccione una línea padre"
                  options={possibleParents.map(x => ({ value: x.id_route, label: x.name }))}
                  customClass="col-12"
                  error={errors.id_parent_route}
                  onChange={(value) => {
                    field.onChange(value);
                    onParentChange(value);
                  }}
                />
              )}
            />

            <Input
              label="Id de línea"
              placeholder="Id de línea"
              customClass="col-12"
              {...register('group_id', { required: 'Este campo es requerido' })}
              error={errors.group_id}
            />

            <Input
              type="number"
              label="Orden de línea"
              placeholder="0"
              customClass="col-12"
              {...register('internal_order', { required: 'Este campo es requerido', min: 1 })}
              error={errors.internal_order}
            />

            <Input
              label="Nombre de línea"
              placeholder="Nombre de línea"
              customClass="col-12"
              {...register('name', { required: 'Este campo es requerido' })}
              error={errors.name}
            />

            <TextArea
              label="Descripción de línea"
              placeholder="Descripción de línea"
              customClass="col-12"
              {...register('description', { maxLength: 255 })}
              error={errors.description}
            />

            <Controller
              name="disabled"
              control={control}
              render={({ field }) => (
                <BooleanButtons
                  {...field}
                  label="Ruta activa"
                  customClass="col-12"
                  trueText="Activa"
                  falseText="Inactiva"
                  handleChange={(val) => setValue('disabled', val)}
                  invert
                />
              )}
            />
          </div>

          <div className="row" style={{ display: tabs === 'times' ? 'block' : 'none' }}>
            <div className="col-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <Controller
                      name="duration"
                      control={control}
                      render={({ field }) => (
                        <TimePicker
                          label="Duración de recorrido"
                          {...field}
                          id="duration"
                          className="form-control w-100"
                          error={errors.duration}
                        />
                      )}
                      rules={{
                        required: 'Este campo es requerido',
                        pattern: {
                          value: /^[0-9]{2}[:]{1}[0-9]{2}([:]{1}[0-9]{2})?$/,
                          message: 'Hora inválida',
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <Input
                      label="Longitud de línea (m)"
                      type="number"
                      id="distance"
                      placeholder="0.0"
                      className="form-control w-100"
                      {...register('distance', { required: 'Este campo es requerido' })}
                      error={errors.distance}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <ul className={`nav nav-tabs ${styles['tabs']}`}>
                <li className="nav-item">
                  <button
                    type="button"
                    className={`btn btn-link nav-link ${weekTab === 'week' && 'active'}`}
                    onClick={() => setWeekTab('week')}
                  >
                    Lun - Vie
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    className={`btn btn-link nav-link ${weekTab === 'sat' && 'active'}`}
                    onClick={() => setWeekTab('sat')}
                  >
                    Sábado
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    className={`btn btn-link nav-link ${weekTab === 'sun' && 'active'}`}
                    onClick={() => setWeekTab('sun')}
                  >
                    Domingo
                  </button>
                </li>
              </ul>
            </div>

            <div className="col-12" style={{ display: weekTab === 'week' ? 'block' : 'none' }}>
              <div className="row">
                <Input
                  label="Unidades en servicio"
                  placeholder="0"
                  customClass="col-6 form-control-sm"
                  {...register('service_units', { required: 'Este campo es requerido' })}
                  error={errors.service_units}
                />

                <Controller
                  name="service_frequency"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      {...field}
                      label="Frecuencia de paso"
                      customClass="col-6 form-control-sm"
                      error={errors.service_frequency}
                    />
                  )}
                  rules={{ required: 'Este campo es requerido' }}
                />

                <Controller
                  name="service_start"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      {...field}
                      label="Hora de inicio"
                      customClass="col-6 form-control-sm mt-5"
                      error={errors.service_start}
                    />
                  )}
                  rules={{ required: 'Este campo es requerido' }}
                />

                <Controller
                  name="service_end"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      {...field}
                      label="Hora de fin"
                      customClass="col-6 form-control-sm mt-5"
                      error={errors.service_end}
                    />
                  )}
                  rules={{ required: 'Este campo es requerido' }}
                />
              </div>
            </div>

            <div className="col-12" style={{ display: weekTab === 'sat' ? 'block' : 'none' }}>
              <div className="row">
                <Input
                  label="Unidades en servicio"
                  placeholder="0"
                  customClass="col-6 form-control-sm"
                  {...register('service_units_saturday', { required: 'Este campo es requerido' })}
                  error={errors.service_units_saturday}
                />

                <Controller
                  name="service_frequency_saturday"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      {...field}
                      label="Frecuencia de paso"
                      customClass="col-6 form-control-sm"
                      error={errors.service_frequency_saturday}
                    />
                  )}
                  rules={{ required: 'Este campo es requerido' }}
                />

                <Controller
                  name="service_start_saturday"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      {...field}
                      label="Hora de inicio"
                      customClass="col-6 form-control-sm mt-5"
                      error={errors.service_start_saturday}
                    />
                  )}
                  rules={{ required: 'Este campo es requerido' }}
                />

                <Controller
                  name="service_end_saturday"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      {...field}
                      label="Hora de fin"
                      customClass="col-6 form-control-sm mt-5"
                      error={errors.service_end_saturday}
                    />
                  )}
                  rules={{ required: 'Este campo es requerido' }}
                />
              </div>
            </div>

            <div className="col-12" style={{ display: weekTab === 'sun' ? 'block' : 'none' }}>
              <div className="row">
                <Input
                  label="Unidades en servicio"
                  placeholder="0"
                  customClass="col-6 form-control-sm"
                  {...register('service_units_sunday', { required: 'Este campo es requerido' })}
                  error={errors.service_units_sunday}
                />

                <Controller
                  name="service_frequency_sunday"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      {...field}
                      label="Frecuencia de paso"
                      customClass="col-6 form-control-sm"
                      error={errors.service_frequency_sunday}
                    />
                  )}
                  rules={{ required: 'Este campo es requerido' }}
                />

                <Controller
                  name="service_start_sunday"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      {...field}
                      label="Hora de inicio"
                      customClass="col-6 form-control-sm mt-5"
                      error={errors.service_start_sunday}
                    />
                  )}
                  rules={{ required: 'Este campo es requerido' }}
                />

                <Controller
                  name="service_end_sunday"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      {...field}
                      label="Hora de fin"
                      customClass="col-6 form-control-sm mt-5"
                      error={errors.service_end_sunday}
                    />
                  )}
                  rules={{ required: 'Este campo es requerido' }}
                />
              </div>
            </div>
          </div>

          <div className="row" style={{ display: tabs === 'style' ? 'block' : 'none' }}>
            <div className="col-12">
              <h5>Personalización de línea</h5>
            </div>

            <Controller
              name="primary_color"
              control={control}
              render={({ field }) => (
                <ColorPicker
                  {...field}
                  label="Color primario"
                  customClass="col-12"
                  value={watch('primary_color')}
                  handleChange={(color) => setValue('primary_color', color)}
                />
              )}
            />

            <Controller
              name="secondary_color"
              control={control}
              render={({ field }) => (
                <ColorPicker
                  {...field}
                  label="Color secundario"
                  customClass="col-12"
                  value={watch('secondary_color')}
                  handleChange={(color) => setValue('secondary_color', color)}
                />
              )}
            />

            <Controller
              name="logo"
              control={control}
              render={({ field: { value, onChange } }) => (
                <ImagePicker
                  name="logo"
                  label="Imagen de línea"
                  customClass="col-12"
                  value={value}
                  setValue={(_, file) => onChange(file)}
                  serverBasePath={process.env.REACT_APP_IMG_ROUTE}
                />
              )}
            />

          {hasConfig ? (
            <div className="col-12">
              <ul className={styles['config-list']}>
                {initialValues.route_configurations.map((config, i) => (
                  <li key={i}>
                    <span>
                      <strong>{config.name}</strong>
                    </span>
                    <img
                      src={`${process.env.REACT_APP_IMG_BUSICON}/${config.image_icon}`}
                      alt={config.name}
                    />
                  </li>
                ))}
              </ul>
              <button
                type="button"
                className={`btn btn-sm btn-link-eliminar ${styles['delete-config']}`}
                onClick={deleteConfigs}
              >
                Eliminar configuración
              </button>
            </div>
            ) : (
              <Controller
                control={control}
                name="unit_config"
                rules={{ required: 'Este campo es requerido' }}
                render={({ field: { onChange, value } }) => (
                  <MultiSelect
                    name="unit_config"
                    customClass="col-12 col-md-12"
                    label="Tipo de unidad"
                    placeholder="Seleccione tipos"
                    items={busTypes}
                    itemKey="id_icon"
                    value={value}
                    onChange={onChange}
                    renderItem={item => item.name}
                    renderInput={item => item.name}
                    error={errors.unit_config}
                  />
                )}
              />
            )
          }
          </div>
        </div>
      </form>
    </div>
  );
};

FormLine.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.any,
  cities: PropTypes.array,
  onCityChange: PropTypes.func,
  tabs: PropTypes.string,
  routes: PropTypes.array,
  onParentChange: PropTypes.func,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
  updateRoutes: PropTypes.func,
};

FormLine.defaultProps = {
  onSubmit: f => f,
  initialValues: {},
  cities: [],
  onCityChange: f => f,
  tabs: '',
  routes: [],
  onParentChange: f => f,
  loading: {
    set: f => f,
    stop: f => f,
  },
  updateRoutes: f => f,
};

export default FormLine;
