import React from 'react';
import PropTypes from 'prop-types';
import styles from './booleanButtons.module.css';

const BooleanButtons = React.forwardRef(({
  trueText,
  falseText,
  invert,
  customClass,
  name,
  label,
  value,
  onChange,
  error,
}, ref) => {
  const rValue = invert ? !value : value;

  const handleToggle = v => {
    onChange(invert ? !v : v);
  };

  return (
    <div className={`wafoformautocomplete form-group wafo-input ${customClass}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={`btn-group ${styles['buttons']}`} role="group" aria-label="Basic example">
        <button
          type="button"
          className={`btn btn-primary ${rValue && styles['not']}`}
          onClick={() => handleToggle(false)}
          ref={ref} // opcional si realmente quieres usar el ref aquí
        >
          {falseText}
        </button>
        <button
          type="button"
          className={`btn btn-primary ${!rValue && styles['not']}`}
          onClick={() => handleToggle(true)}
        >
          {trueText}
        </button>
      </div>
      {error && <div className="invalid-feedback d-block">{error.message}</div>}
    </div>
  );
});

BooleanButtons.propTypes = {
  trueText: PropTypes.string,
  falseText: PropTypes.string,
  invert: PropTypes.bool,
  customClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.object,
};

BooleanButtons.defaultProps = {
  trueText: 'True',
  falseText: 'False',
  invert: false,
  customClass: '',
  label: undefined,
  value: false,
  error: null,
};

export default BooleanButtons;
