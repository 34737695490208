import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import moment from 'moment';
import 'moment/locale/es';
import confirmModal from '../../modals/confirm/confirm';
import useDynamicTable from '#hooks/useDynamicTable';
import DataTable from '../DataTable';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';

const TablePois = ({ loading }) => {
  const getData = React.useCallback(
    async ({ size, page, search }) => {
      loading.set();
      const [resp, error] = await of(
        roAPI.get('/pois', {
          params: {
            limit: size,
            page,
            nocache: true,
            ...(search && { search }),
          },
        }),
      );
      loading.stop();

      if (error) {
        console.error(error);
        return { rows: [], count: 0 };
      }

      const rows = resp.rows.map(row => ({
        id: row.id_poi,
        name: row.name,
        address: row.address,
        date: row.updated_at,
        options: { id: row.id_poi, latlng: [row.latitude, row.longitude], poi: row },
      }));

      return { rows, count: parseInt(resp.count, 10) };
    },
    [loading],
  );

  const {
    list,
    count,
    currentPage,
    size,
    handlePageChange,
    handleSizeChange,
    handleSearch,
    loadItems,
  } = useDynamicTable(getData, 1, 10);  

  const handleDelete = React.useCallback(
    id => {
      async function deletePOI() {
        loading.set();
        const [, err] = await of(roAPI.delete(`/pois/${id}`));
        if (!err) {
          loadItems();
        }
        loading.stop();
      }
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            También será eliminado de las líneas donde aparece.
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: deletePOI,
          },
        ],
      });
    },
    [loading, loadItems],
  );

  const columnsConfig = React.useMemo(
    () => ({
      id: val => <span>{val}</span>,
      name: val => <span>{val}</span>,
      address: val => <span>{val}</span>,
      date: val => <span>{moment(val).format('DD MMMM YYYY HH:mm')}</span>,
      options: val => (
        <>
          <Link
            className="btn btn-sm btn-primary"
            to={{ pathname: `/panel/pois/poi/${val.id}`, state: { poi: val.poi } }}
            style={{ marginRight: '.25rem' }}
          >
            <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
            <span>Editar</span>
          </Link>
          <button type="button" className="btn btn-sm btn-danger" onClick={() => handleDelete(val.id)}>
            <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: '.5rem' }} />
            Eliminar
          </button>
        </>
      ),
    }),
    [handleDelete],
  );

  return (
    <div>
      <DataTable
        columns={['ID', 'Nombre', 'Dirección', 'Última actualización', 'Opciones']}
        list={list}
        currentPage={currentPage}
        size={size}
        totalRows={count}
        handlePageChange={handlePageChange}
        handleSizeChange={handleSizeChange}
        handleSearch={handleSearch}
        columnsConfig={columnsConfig}
        tableClass="table table-striped table-sm"
        tableWrapperClass="table-une"
        noRowsMessage="Sin puntos de interés"
        loadItems={loadItems}
      />
    </div>
  );
};

TablePois.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

TablePois.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(TablePois);
