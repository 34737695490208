import React from 'react';
import PropTypes from 'prop-types';

const TextArea = React.forwardRef(({
  label,
  placeholder,
  name,
  error,
  customClass = "",
  style = {},
  ...props
}, ref) => {
  return (
    <div className={`mb-4 ${customClass}`} style={{ width: '100%' }}>
      {label && <label className="form-label text-black">{label}</label>}
      <textarea
        id={name}
        name={name}
        ref={ref}
        className={`form-control ${error ? 'is-invalid' : ''}`}
        placeholder={placeholder}
        style={{ ...style, minHeight: '50px' }}
        {...props}
      />
      {error && <div className="invalid-feedback">{error.message}</div>}
    </div>
  );
});

TextArea.displayName = 'TextArea';

TextArea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string
  }),
  customClass: PropTypes.string,
  style: PropTypes.object,
};

TextArea.defaultProps = {
  customClass: "",
  style: {},
};

export default TextArea;
