import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import BooleanButtons from '../customForms/booleanButtons/booleanButtons';
import Input from '../../custom/Input';
import Select from '../../custom/Select';
import SelectRemote from '../../custom/SelectRemote';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { roAPI } from '#utils/axiosAPI';
import { useForm, Controller } from 'react-hook-form';

const initialValues = {
  id_route: null,
  id_city: null,
  id_device: null,
  code: null,
  brand: null,
  plate_number: null,
}

const FormBus = ({ history, match: { params }, loading }) => {
  const { register, control, setValue, handleSubmit, formState: { errors } } = useForm();
  const [vehicle, setVehicle] = React.useState(initialValues);
  const [cities, setCities] = React.useState([]);
  const [routes, setRoutes] = React.useState([]);
  const [busses, setBusses] = React.useState([]);
  const [idCity, setIdCity] = React.useState(null);
  // const [idRoute, setIdRoute] = React.useState(null);

  React.useEffect(() => {
    async function getCities() {
      loading.set();
      const [res] = await of(
        roAPI.get('/cities', {
          params: { disabled: false },
        }),
      );
      setCities(res.rows);
      loading.stop();
    }
    async function getDevices() {
      loading.set();
      const [res] = await of(
        roAPI.get('/vehicles/devices', {}),
      );
      setBusses(res.rows);
      loading.stop();
    }
    getCities();
    getDevices();
  }, [loading]);

  React.useEffect(() => {
    if (idCity != null) {
      const getRoutes = async () => {
        try {
          loading.set();
          const res = await roAPI.get(`/routes/city/${idCity}`, {
            params: {
              nocache: true,
              disabled: '0,1',
            },
          });
          setRoutes(res);
          loading.stop();
        } catch (error) {
          loading.stop();
        }
      };
  
      getRoutes();
    }
  }, [idCity, loading]);

  React.useEffect(() => {
    if (params.id) {
      const fetchVehicleData = async () => {
        try {
          loading.set();
          const [res] = await of(roAPI.get(`/vehicles/custom/${params.id}`));
  
          setValue("id_city", res.city.id_city);
          setValue("id_route", res.route.id_route);
          setValue("id_device", { id: res.id_device });
          setValue("code", res.code);
          setValue("plate_number", res.plate_number);
          setValue("brand", res.brand);
          if (typeof res.disabled === 'boolean') {
            setValue("disabled", res.disabled);
          }
  
          setVehicle({ 
            ...res, 
            id_route: res.route.id_route, 
            id_city: res.city.id_city, 
            id_device: { id: res.id_device }, 
            ...(params.id ? { disabled: res.disabled } : {}) 
          });
          setIdCity(res.city.id_city);
          // setIdRoute(res.route.id_route);
          loading.stop();
        } catch (error) {
          loading.stop();
        }
      };
  
      fetchVehicleData();
    }
  }, [params.id, setValue, loading]);

  const onSubmit = async values => {
    try {
      loading.set();
      const newVehicle = { brand: values.brand, code: values.code, plate_number: values.plate_number, id_route: Number(values.id_route), id_city: Number(values.id_city), id_device: values.id_device.id, ...(params.id && typeof values.disabled == 'boolean' ? { disabled: values.disabled } : {}) };
      const res = params.id ? await roAPI.put(`/vehicles/custom/${params.id}`, newVehicle) : await roAPI.post('/vehicles/custom', newVehicle);
      setVehicle(prevVehicle => ({ ...prevVehicle, ...res }));
      history.push('/panel/autobuses/gestionar');
      loading.stop();
    } catch (error) {
      loading.stop();
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <button type="button" className={`btn btn-link back`} onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
            <span>Regresar</span>
          </button>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <form key={params.id} id="formBus" locale="es" onSubmit={handleSubmit(onSubmit)} values={vehicle}>
            <div className="row">
              <div className="col-md-6">
                <Controller
                  name="id_city"
                  control={control}
                  rules={{ required: 'Selecciona una ciudad' }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="Ciudad"
                      defaultValue=""
                      placeholder="Selecciona la ciudad"
                      options={cities.map(x => ({ value: x.id_city, label: x.name }))}
                      customClass="col-12 mb-3"
                      error={errors.id_city}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setIdCity(e.target.value);  
                      }}
                      disabled={!!initialValues.id_city}
                    />
                  )}
                />
                <Input 
                  customClass="col-12 mb-3"
                  label="Clave"
                  placeholder="Clave"
                  {...register('code', { required: 'Este campo es requerido', maxLength: 254 })}
                  error={errors.code}
                />
                <Input 
                  customClass="col-12"
                  label="Placa"
                  placeholder="Placa"
                  {...register('plate_number', { required: 'Este campo es requerido', maxLength: 254 })}
                  error={errors.plate_number}
                />
              </div>
              <div className="col-md-6">
                <Controller
                  name="id_route"
                  control={control}
                  rules={{ required: 'Selecciona una ruta' }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="Ruta"
                      defaultValue=""
                      placeholder="Selecciona una ruta"
                      options={routes.map(x => ({ value: x.id_route, label: x.name }))}
                      customClass="col-12 mb-3"
                      error={errors.id_route}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        // setIdRoute(e.target.value);
                      }}
                      disabled={idCity == null}
                    />
                  )}
                />
                <Controller
                  name="id_device"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Seleccione un dispositivo' }}
                  render={({ field }) => (
                    <SelectRemote
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      customClass="col-12"
                      label="ID de dispositivo"
                      placeholder="Seleccione un dispositivo"
                      items={busses}
                      filterItems={(items, query) =>
                        items.filter(item => 
                          item.id.toLowerCase().includes(query.toLowerCase()) || 
                          item.code.toLowerCase().includes(query.toLowerCase())
                        )
                      }
                      renderInput={item => `${item.id}`}
                      renderItem={item => (
                        <p style={{ margin: '0' }}>
                          <span>{item.id}</span>
                          <br />
                          <span style={{ fontSize: '0.8em', color: '#777' }}>{item.code}</span>
                        </p>
                      )}
                      error={errors.id_device}
                    />
                  )}
                />
                <Input 
                  customClass="col-12"
                  label="Marca"
                  placeholder="Marca"
                  {...register('brand', { required: 'Este campo es requerido', maxLength: 254 })}
                  error={errors.brand}
                />
              </div>
            </div>
            {params.id && (
              <div className="d-flex justify-content-center mt-4">
                <Controller
                  name="disabled"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <BooleanButtons
                      {...field}
                      customClass="col-12"
                      label="Autobús activo"
                      trueText="Activa"
                      falseText="Inactiva"
                      invert={true}
                      error={error}
                    />
                  )}
                />
              </div>
            )}
          </form>
          <div className="d-flex justify-content-center mt-4">
            <button type="submit" form="formBus" className="btn btn-une btn-submit">
              Guardar cambios
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

FormBus.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.any,
  }),
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

FormBus.defaultProps = {
  history: {
    goBack: f => f,
    push: f => f,
  },
  match: {
    params: {},
  },
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  state => ({
    cities: state.cities,
    city: state.city,
  }),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    changeCity: city => dispatch(actions.changeCity(city)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(FormBus);
