import React from 'react';
import useUpdateCities from '#hooks/useUpdateCities';
import MainMap from '#components/maps/mainMap/mainMap';
import Select from '#components/custom/Select';
import { useSelector, useDispatch } from 'react-redux';
import { roAPI } from '#utils/axiosAPI';
import { actions } from '#redux/reducers';

const MonitorMap = () => {
  const [route, setRoute] = React.useState({});
  const [busses, setBusses] = React.useState([]);

  useUpdateCities();

  const dispatch = useDispatch();

  const { city, cities, routes } = useSelector(state => ({
    cities: state.cities,
    city: state.city,
    routes: state.routes,
  }));

  React.useEffect(() => {
    async function fetchRoutes() {
      try {
        const resp = await roAPI.get(`/routes/city/${city}`);
        dispatch(actions.updateRoutes(resp));
      } catch (error) {
        //
      }
    }
    fetchRoutes();
  }, [city, dispatch]);

  React.useEffect(() => {
    async function fetchBusses() {
      try {
        const res = await roAPI.get(`/locations/${route.group_id}`);
        setBusses(res);
      } catch (error) {
        // handle
      }
    }

    if (route.group_id) {
      fetchBusses();
    }

    const interval = setInterval(() => {
      if (route.group_id) {
        fetchBusses();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [route]);

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="row mb-3">
            <Select
              customClass="col-3"
              label="Ciudad"
              placeholder="Seleccione ciudad"
              options={cities.map(x => ({ value: x.id_city, label: x.name }))}
              value={city.toString()}
              onChange={({ target: { value } }) => {
                dispatch(actions.changeCity(value));
                setRoute({});
              }}
            />

            <Select
              customClass="col-3"
              label="Línea"
              placeholder="Seleccione línea"
              options={routes
                .filter(x => x.id_route && x.id_route !== -1)
                .map(x => ({
                  value: x.id_route,
                  label: x.name,
                }))}
              value={route.id_route || ""}
              onChange={({ target: { value } }) => {
                try {
                  if (value) {
                    const selectedRoute = routes.find(x => x.id_route === value);
                    setRoute(selectedRoute);
                  }
                } catch (error) {
                  //
                }
              }}
            />
          </div>
          <div className="row">
            <div className="col-12">
              <div style={{ height: '50vh' }}>
                <MainMap city={city} cities={cities} route={route} busses={busses} showButtons={false} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonitorMap;
