import React from 'react';
import PropTypes from 'prop-types';
import LinesMap from './linesMap/linesMap';
import NewLine from './newLine/newLine';
import useUpdateCities from '#hooks/useUpdateCities';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { Switch, Route, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const Lines = ({ match, updateCities, loading }) => {
  useUpdateCities(updateCities, loading, false);

  return (
    <div data-testid="Notificaciones">
      <div className="section-header">
        <h2 className="title">Líneas de autobús</h2>
        <Route
          exact
          path={`${match.url}/`}
          render={() => (
            <Link to="/panel/lineas/linea" className="btn btn-primary">
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '.5rem' }} />
              Agregar línea
            </Link>
          )}
        />
      </div>

      <div className="card">
        <div className="card-body">
          <Switch>
            <Route path={`${match.url}/linea/:id`} component={NewLine} />
            <Route path={`${match.url}/linea`} component={NewLine} />
            <Route path={`${match.url}`} component={LinesMap} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

Lines.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  updateCities: PropTypes.func,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

Lines.defaultProps = {
  match: {},
  updateCities: f => f,
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(Lines);
