import React from 'react';
import PropTypes from 'prop-types';
import useUpdateCities from '#hooks/useUpdateCities';
import moment from 'moment';
import confirmModal from '#components/modals/confirm/confirm';
import useDynamicTable from '#hooks/useDynamicTable';
import Select from '../../custom/Select';
import DataTable from '../DataTable'; 
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';
import { information_types } from '#utils/utils';

const TableCredentialsInformation = ({ loading, cities, updateCities }) => {
  const [ciudad, setCiudad] = React.useState('');

  useUpdateCities(updateCities, loading, false);

  const getData = React.useCallback(
    async ({ size, page, search, filters }) => {
      try {
        loading.set();
        const resp = await roAPI.get('/credentials/instructions', {
          params: {
            limit: size,
            page,
            ...(search && { search }),
            ...(filters.id_city && { id_city: filters.id_city }),
          },
        });

        const rows = resp.rows.map(row => ({
          id: row.id_ci,
          city: row.id_city,
          type: row.type,
          title: row.title,
          order: row.order,
          date: row.updated_at,
          options: row,
        }));

        loading.stop();
        return { rows, count: resp.count };
      } catch (error) {
        loading.stop();
        console.error(error);
        return { rows: [], count: 0 };
      }
    },
    [loading],
  );

  const {
    count,
    list,
    currentPage,
    size,
    handlePageChange,
    handleSizeChange,
    handleSearch,
    loadItems,
    handleFilterChange,
  } = useDynamicTable(getData, 1, 10, { id_city: '' });

  const handleDelete = React.useCallback(
    id => {
      async function deleteInformation() {
        try {
          loading.set();
          await roAPI.delete(`/credentials/instructions/${id}`);
          loadItems();
          loading.stop();
        } catch (error) {
          loading.stop();
        }
      }
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            Será eliminada y no se mostrará dentro de la aplicación.
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: deleteInformation,
          },
        ],
      });
    },
    [loading, loadItems],
  );

  const columnsConfig = React.useMemo(
    () => ({
      id: val => <span>#{val}</span>,
      city: val => {
        const city = cities.find(c => c.id_city === val);
        return <span>{city && city.name}</span>;
      },
      type: val => {
        const type = information_types.find(t => t.id === val);
        return <span>{type && type.name}</span>;
      },
      title: val => <span>{val}</span>,
      order: val => <span>{val}</span>,
      date: val => <span>{moment(val).format('DD MMMM YYYY')}</span>,
      options: val => (
        <>
          <Link
            className="btn btn-sm btn-primary"
            to={{ pathname: `/panel/credencializacion/informacion/nueva/${val.id_ci}`, state: { information: val } }}
            style={{ marginRight: '.25rem' }}
          >
            <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
            <span>Editar</span>
          </Link>
          <button type="button" className="btn btn-sm btn-danger" onClick={() => handleDelete(val.id_ci)}>
            <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: '.5rem' }} />
            Eliminar
          </button>
        </>
      ),
    }),
    [cities, handleDelete],
  );

  const cityFilter = (
    <Select
      value={ciudad}
      placeholder="Filtre por ciudad"
      onChange={({ target: { value } }) => {
        setCiudad(value);
        handleFilterChange('id_city', value);
      }}
      options={cities.map(x => ({
        value: x.id_city,
        label: x.name,
      }))}
    />
  );

  return (
    <div>
      <DataTable
        columns={['ID', 'Ciudad', 'Tipo', 'Título', 'Orden', 'Fecha', 'Opciones']}
        list={list}
        currentPage={currentPage}
        size={size}
        totalRows={count}
        handlePageChange={handlePageChange}
        handleSizeChange={handleSizeChange}
        handleSearch={handleSearch}
        columnsConfig={columnsConfig}
        tableClass="table table-striped table-sm"
        tableWrapperClass="table-une"
        noRowsMessage="Sin información de credencialización para mostrar"
        loadItems={loadItems}
        customFilters={cityFilter}
      />
    </div>
  );
};

TableCredentialsInformation.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
  cities: PropTypes.array,
  updateCities: PropTypes.func,
};

TableCredentialsInformation.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
  cities: [],
  updateCities: f => f,
};

export default reduxConnect(
  state => ({
    cities: state.cities,
  }),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(TableCredentialsInformation);
