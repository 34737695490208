import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';
import styles from './busses.module.css';
import confirmModal from '../../modals/confirm/confirm';
import SelectRemote from '../../custom/SelectRemote';
import useDynamicTable from '#hooks/useDynamicTable';
import DataTable from '../DataTable';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { roAPI } from '#utils/axiosAPI';
import { useForm, Controller } from 'react-hook-form';

const TableBussesHide = ({ loading }) => {
  const { handleSubmit, control, formState: { errors } } = useForm();
  const [key, setKey] = React.useState(Math.random());
  const [busses, setBusses] = React.useState([]);
  const [hidden, setHidden] = React.useState([]);

  React.useEffect(() => {
    async function getBusses() {
      try {
        loading.set();
        const busses = await roAPI.get('/vehicles/all');
        if (Array.isArray(busses)) {
          setBusses(busses);
        }
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    getBusses();
  }, [loading]);

  const getData = React.useCallback(async ({ search, size, page }) => {
    try {
      loading.set();
      const resp = await roAPI.get('/vehicles/hiddens');
      const filteredData = resp.rows.filter(row =>
        !search || row.vehicle_code.toLowerCase().includes(search.toLowerCase())
      );
  
      const startIndex = (page - 1) * size;
      const paginatedData = filteredData.slice(startIndex, startIndex + size);
  
      const mappedData = paginatedData.map(row => ({
        vehicle: row.vehicle_code,
        date: row.created_at,
        options: row.vehicle_code
      }));
  
      setHidden(mappedData);
  
      return {
        rows: mappedData,
        count: filteredData.length,
      };
    } catch (error) {
      return { rows: [], count: 0 };
    } finally {
      loading.stop();
    }
  }, [loading]);
  

  const {
    count,
    currentPage,
    size,
    handlePageChange,
    handleSizeChange,
    handleSearch,
    loadItems,
  } = useDynamicTable(getData, 1, 10);

  const onSubmit = async values => {
    async function doSubmit() {
      try {
        loading.set();
        await roAPI.post('/vehicles/hiddens', {
          vehicle_code: values.bus.code,
        });
        setKey(Math.random());
        loadItems();
        loading.stop();
      } catch (error) {
        loading.stop();
        console.error(error);
      }
    }
    confirmModal({
      message: (
        <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
          Esta información se verá reflejada dentro de la aplicación móvil y sitio web.
          <br />
          ¿Desea continuar?
        </p>
      ),
      buttons: [
        {
          label: 'Cancelar',
          class: 'btn-secondary',
        },
        {
          label: 'Aceptar',
          class: 'btn-une',
          onClick: doSubmit,
        },
      ],
    });
  };

  const removeAssign = React.useCallback(
    code => {
      async function doRemove() {
        try {
          loading.set();
          await roAPI.delete(`/vehicles/hiddens/${code}`);
          loadItems();
          loading.stop();
        } catch (error) {
          loading.stop();
        }
      }
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            El autobús será visible en el mapa.
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: doRemove,
          },
        ],
      });
    },
    [loading, loadItems],
  );

  const removeAllAssign = React.useCallback(() => {
    async function doRemove() {
      try {
        loading.set();
        await roAPI.delete('/vehicles/hiddens/purge');
        loadItems();
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    confirmModal({
      message: (
        <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
          Todos los autobuses ocultos serán visibles en el mapa.
          <br />
          ¿Desea continuar?
        </p>
      ),
      buttons: [
        {
          label: 'Cancelar',
          class: 'btn-secondary',
        },
        {
          label: 'Aceptar',
          class: 'btn-une',
          onClick: doRemove,
        },
      ],
    });
  }, [loading, loadItems]);

  const columnsConfig = React.useMemo(
    () => ({
      vehicle: val => <span>{val}</span>,
      date: val => <span>{moment(val).format('DD-MM-YYYY HH:mm')}</span>,
      options: {
        render: function col(val) {
          return (
            <>
              <button type="button" className="btn btn-sm btn-danger" onClick={() => removeAssign(val)}>
                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '.5rem' }} />
                Cancelar
              </button>
            </>
          );
        },
        style: {
          whiteSpace: 'nowrap',
        },
      },
    }),
    [removeAssign],
  );

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <h5>Ocultar autobuses</h5>
        </div>
      </div>

      <form key={key} locale="es" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="bus"
          control={control}
          defaultValue=""
          rules={{ required: 'Selecciona un autobús' }}
          render={({ field }) => (
            <SelectRemote
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              customClass="col-3"
              label="Autobús"
              placeholder="Seleccione un autobús"
              items={busses}
              filterItems={(items, query) =>
                items.filter(item => item.code.toLowerCase().includes(query.toLowerCase()))
              }
              renderInput={item => `${item.code}`}
              renderItem={item => <p className="autocomplete-item">Unidad: {item.code}</p>}
              error={errors.bus}
            />
          )}
        />

        <div className={`col-3 ${styles['buttons']}`}>
          <button type="submit" className="btn btn-une">
            <FontAwesomeIcon icon={faEyeSlash} style={{ marginRight: '.5rem' }} />
            <span>Ocultar</span>
          </button>
        </div>
      </form>

      <div className="row">
        <div className="col-12">
          <p className={styles['desc']}>
            Los autobuses configurados aquí no se mostrarán en la aplicación móvil y el mapa web. Seleccione la unidad deseada para realizar
            la configuración.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <DataTable
            columns={['Código unidad', 'Fecha de configuración', 'Opciones']}
            list={hidden}
            currentPage={currentPage}
            size={size}
            totalRows={count}
            handlePageChange={handlePageChange}
            handleSizeChange={handleSizeChange}
            handleSearch={handleSearch}
            columnsConfig={columnsConfig}
            tableClass="table table-striped table-sm"
            tableWrapperClass="table-une"
            noRowsMessage="Sin autobuses ocultos"
            loadItems={loadItems}
            customFilters={
              <button
                type="button"
                className="btn btn-primary"
                disabled={!count}
                onClick={removeAllAssign}
              >
                <FontAwesomeIcon icon={faEraser} />
              </button>
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
};

TableBussesHide.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

TableBussesHide.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(TableBussesHide);
