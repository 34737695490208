import React from 'react';
import PropTypes from 'prop-types';
import styles from './formRequestActivate.module.css';
import Input from '../../custom/Input';
import { useForm } from 'react-hook-form';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';

const FormRequestActivate = ({ onAfterSubmit, alert }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (values) => {
    try {
      await roAPI.post('/auth/request/email', {
        email: values.email,
      });
      onAfterSubmit('/auth/inicio', 'redirect');
      alert({
        type: 'success',
        title: '¡Estas a un solo paso!',
        message: 'Se ha enviado un nuevo código de verificación. Por favor revisa tu bandeja de entrada.',
      });
    } catch (error) {
      // Handle error
    }
  };

  return (
    <div data-testid="FormLogin">
      
      <p>Se enviará el correo de activación con las instrucciones a tu correo.</p>
      
      <form id="formLogin" onSubmit={handleSubmit(onSubmit)} className={`row g-3 ${styles.form}`}>
        <div className="col-12">
          <Input
            label="Correo electrónico"
            placeholder="Correo electrónico"
            type="email"
            {...register('email', {
              required: 'Este campo es requerido',
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'Correo electrónico inválido',
              },
            })}
            error={errors.email}
          />
        </div>
      </form>

      <button type="submit" form="formLogin" className={`btn btn-une ${styles['btn-submit']}`}>
        Reenviar correo de activación
      </button>

    </div>
  );
};

FormRequestActivate.propTypes = {
  onAfterSubmit: PropTypes.func,
  alert: PropTypes.func,
};

FormRequestActivate.defaultProps = {
  onAfterSubmit: f => f,
  alert: f => f,
}

export default reduxConnect(
  () => ({}),
  dispatch => ({
    alert: alert => dispatch(actions.alert(alert)),
  }),
)(FormRequestActivate);
