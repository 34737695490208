import React from 'react';
import PropTypes from 'prop-types';
import styles from './multiSelect.module.css';
import useClickOutside from '#hooks/useClickOutside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';

const MultiSelect = ({
  name,
  customClass,
  label,
  placeholder,
  items,
  itemKey,
  renderItem,
  renderInput,
  value = [],
  onChange,
  error,
}) => {
  const [dropdown, setDropdown] = React.useState(false);
  const toggleDropdown = () => setDropdown(dd => !dd);

  const clickRef = useClickOutside(dropdown, () => {
    toggleDropdown();
  });

  const handleItemClick = item => {
    let updatedValue;
    const index = value.findIndex(x => x[itemKey] === item[itemKey]);

    if (index !== -1) {
      updatedValue = [...value.slice(0, index), ...value.slice(index + 1)];
    } else {
      updatedValue = [...value, item];
    }

    onChange(updatedValue);
  };

  const display = React.useMemo(() => {
    let inputDisplay = '';
    let itemsDisplay = [...items];
    itemsDisplay.forEach((item, i) => {
      if (value.findIndex(x => x[itemKey] === item[itemKey]) !== -1) {
        inputDisplay += `${renderInput(item)}, `;
        itemsDisplay[i].wafoSelected = true;
      } else {
        itemsDisplay[i].wafoSelected = false;
      }
    });
    return {
      inputDisplay,
      itemsDisplay,
    };
  }, [items, itemKey, renderInput, value]);

  return (
    <div ref={clickRef} className={`${customClass}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={`${styles['input']} ${dropdown && styles['input-down']}`}>
        <input
          type="text"
          id={name}
          name={name}
          placeholder={placeholder}
          className={`form-control ${error ? 'is-invalid' : ''}`}
          value={display.inputDisplay}
          onClick={toggleDropdown}
          readOnly
        />
        <button type="button" className="btn btn-light" onClick={toggleDropdown}>
          <FontAwesomeIcon icon={dropdown ? faCaretUp : faCaretDown} />
        </button>
      </div>
      {dropdown && (
        <div className={styles['list']}>
          {items.length > 0 && (
            <ul>
              {display.itemsDisplay.map((item, i) => (
                <li key={i} onClick={() => handleItemClick(item)}>
                  <span>{renderItem(item)}</span>
                  <FontAwesomeIcon icon={item.wafoSelected ? faCheckSquare : faSquare} />
                </li>
              ))}
            </ul>
          )}
          {items.length === 0 && (
            <div className={styles['list-empty']}>
              <div>Sin elementos a elegir</div>
            </div>
          )}
        </div>
      )}
      {error && <div className="invalid-feedback">{error.message}</div>}
    </div>
  );
};

MultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  items: PropTypes.array,
  itemKey: PropTypes.string,
  renderItem: PropTypes.func,
  renderInput: PropTypes.func,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.object,
};

MultiSelect.defaultProps = {
  customClass: '',
  label: '',
  placeholder: '',
  items: [],
  itemKey: 'id',
  renderItem: item => (typeof item === 'string' ? item : 'Item option'),
  renderInput: item => (typeof item === 'string' ? item : 'Item option'),
  value: [],
  error: null,
};

export default MultiSelect;
