import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';
import confirmModal from '#components/modals/confirm/confirm';
import MultiSelect from '#components/forms/customForms/multiSelect/multiSelect';
import SelectRemote from '../../custom/SelectRemote';
import styles from './busses.module.css';
import useDynamicTable from '#hooks/useDynamicTable';
import DataTable from '../DataTable';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faTimes } from '@fortawesome/free-solid-svg-icons';
import { connect as reduxConnect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

const TableBussesConfiguration = ({ loading }) => {
  const { handleSubmit, control, formState: { errors, isValid } } = useForm({ mode: 'onChange' });

  const [key, setKey] = React.useState(Math.random());
  const [busses, setBusses] = React.useState([]);
  const [assigns, setAssigns] = React.useState([]);
  const [busTypes, setBusTypes] = React.useState([]);

  const getData = React.useCallback(async () => {
    try {
      loading.set();
      const resp = await roAPI.get('/vehicles/configurations');
      const corregido = Object.entries(resp).reduce((accumulator, [code, configs]) => {
        let created_at = null;
        const configurations = configs
          .map(config => {
            if (!created_at || created_at < config.created_at) {
              created_at = config.created_at;
            }
            return {
              id_vc: config.id_vc,
              id_icon: config.id_icon,
              order: config.order,
              created_at: config.created_at,
              name: config.configuration_vehicle.name,
              image_icon: config.configuration_vehicle.image_icon,
              primary_color: config.configuration_vehicle.primary_color,
            };
          })
          .sort((a, b) => parseInt(a.order) - parseInt(b.order));
        accumulator.push({
          vehicle_code: code,
          configurations,
          created_at,
          options: code,
        });
        return accumulator;
      }, []);
      setAssigns(corregido);
      loading.stop();
    } catch (error) {
      loading.stop();
    }
  }, [loading]);

  React.useEffect(() => {
    getData();
  }, [getData]);

  React.useEffect(() => {
    async function getBusses() {
      try {
        loading.set();
        const busses = await roAPI.get('/vehicles/all');
        if (Array.isArray(busses)) {
          setBusses(busses);
        }
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    getBusses();
  }, [loading]);

  React.useEffect(() => {
    async function getTypes() {
      try {
        loading.set();
        const types = await roAPI.get('/configurations/icons', {
          params: {
            limit: 999,
            page: 1,
          },
        });
        setBusTypes(types.rows);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    getTypes();
  }, [loading]);

  const getDataForTable = React.useCallback(({ size, page, search }) => {
    const filteredData = assigns.filter(bus => 
      bus.vehicle_code.toLowerCase().includes(search.toLowerCase())
    );
  
    const startIndex = (page - 1) * size;
    const paginatedData = filteredData.slice(startIndex, startIndex + size);
  
    return {
      rows: paginatedData.map(bus => ({
        ...bus,
        name: bus.vehicle_code,
      })),
      count: filteredData.length,
    };
  }, [assigns]);

  const {
    count,
    list,
    currentPage,
    size,
    handlePageChange,
    handleSizeChange,
    handleSearch,
    loadItems,
  } = useDynamicTable(getDataForTable, 1, 10);

  const onSubmit = async values => {
    if (!isValid) {
      return; 
    }

    async function doSubmit() {
      try {
        await Promise.all(
          values.config.map(config =>
            roAPI.post('/vehicles/configurations', {
              id_icon: config.id_icon,
              order: config.order || 10,
              vehicle_code: values.bus.code,
            }),
          ),
        );
        setKey(Math.random());
        getData();
        loading.stop();
      } catch (error) {
        loading.stop();
        console.error(error);
      }
    }
    confirmModal({
      message: (
        <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
          Esta información se verá reflejada dentro de la aplicación móvil y sitio web.
          <br />
          ¿Desea continuar?
        </p>
      ),
      buttons: [
        {
          label: 'Cancelar',
          class: 'btn-secondary',
        },
        {
          label: 'Aceptar',
          class: 'btn-une',
          onClick: doSubmit,
        },
      ],
    });
  };

  const removeAssign = React.useCallback(
    code => {
      async function doRemove() {
        try {
          loading.set();
          const assign = assigns.find(x => x.vehicle_code === code);
          if (assign) {
            await Promise.all(
              assign.configurations.map(config => roAPI.delete(`/vehicles/configurations/${config.id_vc}`)),
            );
            getData();
          }
          loading.stop();
        } catch (error) {
          loading.stop();
        }
      }
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            El autobús ya no mostrará el icono especial.
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: doRemove,
          },
        ],
      });
    },
    [assigns, loading, getData],
  );

  const columnsConfig = React.useMemo(
    () => ({
      vehicle_code: val => <span>{val}</span>,
      configurations: val => (
        <div className={styles['iconos-row']}>
          {val.map(config => (
            <div
              key={config.id_vc}
              className={styles['icono-wrapper']}
              style={{ backgroundColor: config.primary_color }}
              title={config.name}
            >
              <img
                className={styles['icono']}
                src={`${process.env.REACT_APP_IMG_BUSICON}/${config.image_icon}`}
                alt="icon"
              />
            </div>
          ))}
        </div>
      ),
      created_at: val => <span>{moment(val).format('DD-MM-YYYY HH:mm')}</span>,
      options: val => (
        <button type="button" className="btn btn-sm btn-danger" onClick={() => removeAssign(val)}>
          <FontAwesomeIcon icon={faTimes} style={{ marginRight: '.5rem' }} />
          Cancelar
        </button>
      ),
    }),
    [removeAssign],
  );

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <h5>Configuración de autobuses</h5>
        </div>
      </div>

      <form key={key} locale="es" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-6">
          <Controller
            name="bus"
            control={control}
            rules={{ required: "El autobús es requerido" }} 
            render={({ field }) => (
              <SelectRemote
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                customClass="col-12"
                label="Autobús"
                placeholder="Seleccione un autobús"
                items={busses}
                filterItems={(items, query) =>
                  items.filter(item => item.code.toLowerCase().includes(query.toLowerCase()))
                }
                renderInput={item => `${item.code}`}
                renderItem={item => <p className="autocomplete-item">Unidad: {item.code}</p>}
                error={errors.bus}
              />
            )}
          />
          </div>

          <div className="col-6">
            <Controller
              control={control}
              name="config"
              rules={{ required: "La configuración es requerida" }}
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  name="config"
                  customClass="col-12"
                  label="Tipo de configuración"
                  placeholder="Seleccione tipos"
                  items={busTypes}
                  itemKey="id_icon"
                  value={value}
                  onChange={onChange}
                  renderItem={item => item.name}
                  renderInput={item => item.name}
                  error={errors.config}
                />
              )}
            />
          </div>
        </div>

        <div className={`col-3 ${styles['buttons']}`}>
          <button type="submit" className="btn btn-une">
            <FontAwesomeIcon icon={faCog} style={{ marginRight: '.5rem' }} />
            <span>Configurar</span>
          </button>
        </div>
      </form>

      <div className="row">
        <div className="col-12">
          <p className={styles['desc']}>
            Los autobuses configurados aquí mostrarán el icono distinto dentro de la aplicación móvil y mapa web.
            Seleccione la unidad deseada para realizar la configuración.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h5 className={styles['sub-separador']}>Autobuses con configuración</h5>
        </div>
      </div>
      
      <DataTable
        columns={['Código unidad', 'Modos', 'Fecha de configuración', 'Opciones']}
        list={list}
        currentPage={currentPage}
        size={size}
        totalRows={count}
        handlePageChange={handlePageChange}
        handleSizeChange={handleSizeChange}
        handleSearch={handleSearch}
        columnsConfig={columnsConfig}
        tableClass="table table-striped table-sm"
        tableWrapperClass="table-une"
        noRowsMessage="No hay autobuses configurados"
        loadItems={loadItems}
      />
    </React.Fragment>
  );
};

TableBussesConfiguration.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

TableBussesConfiguration.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(TableBussesConfiguration);
