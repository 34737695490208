import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBoXB6TUWSAogqIky8zCOuRMqBf8CAZWR4",
  authDomain: "transporte-urbano-a7faa.firebaseapp.com",
  projectId: "transporte-urbano-a7faa",
  storageBucket: "transporte-urbano-a7faa.appspot.com",
  messagingSenderId: "336401143369",
  appId: "1:336401143369:web:0b4dd5147452ee26515198"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const getToken = async () => {
  try {
    let storedToken = sessionStorage.getItem("fb-token");

    if (auth.currentUser) {
      const newToken = await auth.currentUser.getIdToken(true);
      if (newToken !== storedToken) {
        sessionStorage.setItem("fb-token", newToken);
        return newToken;
      }
      return storedToken;
    }

    await signInAnonymously(auth);
    const token = await auth.currentUser.getIdToken(true);
    sessionStorage.setItem("fb-token", token);
    return token;
  } catch (error) {
    console.error("Error getting token:", error);
    throw error;
  }
};

export async function initializeFbToken() {
  try {
    const fbToken = await getToken();
    return fbToken;
  } catch (error) {
    console.error("Error initializing fb-token:", error);
    throw error;
  }
}

export const validateFirebaseToken = async (token) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      return false;
    }

    const newToken = await user.getIdToken(true);

    if (newToken !== token) {
      return false;
    }

    return true;
  } catch (error) {
    console.error("Error validating Firebase token:", error);
    return false;
  }
};
